import posthog from 'posthog-js';
import { ReactElement } from 'react';
import { RouteProps } from 'react-router-dom';

interface TrackedRouteProps extends RouteProps {
  track?: boolean;
  children: ReactElement<any>;
}

const TrackedRoute = ({ children, track = false }): TrackedRouteProps => {
  if (track) {
    if (!localStorage.getItem('session_id')) {
      posthog.opt_in_capturing();
      posthog.startSessionRecording();
      localStorage.setItem('session_id', posthog.get_session_id());
    }
  } else {
    posthog.opt_out_capturing();
    if (localStorage.getItem('session_id')) {
      posthog.stopSessionRecording();
      localStorage.removeItem('session_id');
    }
  }
  return children;
};

export default TrackedRoute;
