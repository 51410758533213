import posthog from 'posthog-js';

export const stopPosthogRecording = () => {
  if (localStorage.getItem('session_id')) {
    posthog.stopSessionRecording();
    posthog.opt_out_capturing();
    localStorage.removeItem('session_id');
  }
};

export const startPosthogRecording = () => {
  if (!localStorage.getItem('session_id')) {
    posthog.opt_in_capturing();
    posthog.startSessionRecording();
    localStorage.setItem('session_id', posthog.get_session_id());
  }
};
