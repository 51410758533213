import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Select,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { queryClient } from 'configs/queryClient';
import { differenceInMonths } from 'date-fns';
import { numberInput } from 'helpers/makeFormFields';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import useStoreInspectionPlanMutation from 'hooks/private/mutations/useStoreInspectionPlanMutation';
import useMaintenanceSystemQuery, { IMaintenanceSystem, Period } from 'hooks/queries/service/useMaintenanceSystemQuery';
import { use } from 'i18next';
import { compact, find, get, isNil, omitBy, values } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getI18n } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

export interface ISelectInspectionForm {
  maintenance_system_id: number | null;
  maintenance_period_ids_combinable: Array<string>;
  maintenance_period_ids_not_combinable: string | null;
  mileage: number | null;
  initial_registration_date: string;
  is_combinable_maintenance_period: boolean | undefined;
}

const getCombinable = (maintenanceSystem: IMaintenanceSystem) => {
  return maintenanceSystem.periods.filter((obj) => obj.combinable) || [];
};

const getNotCombinable = (maintenanceSystem: IMaintenanceSystem) => {
  return maintenanceSystem.periods.filter((obj) => !obj.combinable) || [];
};

const SelectInspectionStep = ({
  setStep,
  setInspectionPlandId,
  initialRegistrationDate,
}: {
  setStep(y: number): void;
  setInspectionPlandId(y: number): void;
  initialRegistrationDate: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const posthog = usePostHog();

  const schema = yup
    .object()
    .shape({
      maintenance_system_id: yup
        .number()
        .label(getI18n().t('forms:maintenance_system_id.label'))
        .typeError(getI18n().t('forms:maintenance_system_id.type_error'))
        .required(),
      mileage: yup
        .number()
        .required(getI18n().t('forms:mileage.validation.required'))
        .label(getI18n().t('forms:mileage.label'))
        .typeError(getI18n().t('forms:mileage.type_error'))
        .nullable(),
      maintenance_period_ids_combinable: yup
        .array()
        .min(1, t('forms:maintenance_period_ids.required'))
        .label(getI18n().t('forms:maintenance_period_ids.label'))
        .when('is_combinable_maintenance_period', {
          is: (not_combinable: any) => not_combinable,
          then: yup
            .array()
            .test('is-valid-array', t('forms:maintenance_period_ids.invalid'), (value) => {
              if (Array.isArray(value) && value.length === 1) {
                return value[0] !== undefined && value[0] !== 'undefined';
              }
              return true;
            })
            .required(),
          otherwise: yup.array().nullable(),
        }),
      maintenance_period_ids_not_combinable: yup
        .string()
        .label(getI18n().t('forms:maintenance_period_ids.label'))
        .when('is_combinable_maintenance_period', {
          is: (combinable: any) => !combinable,
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
    })
    .required();

  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';

  const [selectedMaintenance, setSelectedMaintenance] = useState<IMaintenanceSystem | null>(null);
  const [suggestedPeriod, setSuggestedPeriod] = useState(false);
  const [displayedSuggestion, setDisplayedSuggestion] = useState('');

  const maintenanceSystemQuery = useMaintenanceSystemQuery(vin);
  const maintenanceSystemData = maintenanceSystemQuery.data?.data;

  const {
    watch,
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    clearErrors,
    setFocus,
    trigger,
    formState: { errors, isValid, submitCount },
    setError,
  } = useForm<ISelectInspectionForm>({
    defaultValues: {
      maintenance_system_id: null,
      maintenance_period_ids_not_combinable: null,
      maintenance_period_ids_combinable: [],
      mileage: null,
      is_combinable_maintenance_period: false,
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const watchMaintenanceSystem = watch('maintenance_system_id');
  const watchMileage = watch('mileage');
  const watch_maintenance_period_ids_combinable = watch('maintenance_period_ids_combinable');

  useEffect(() => {
    if (watchMaintenanceSystem) {
      setFocus('mileage');
    }
  }, [watchMaintenanceSystem, setFocus]);

  const processedData = values(
    omitBy(
      maintenanceSystemData?.map((system) => {
        const combinable = getCombinable(system);
        const notCombinable = getNotCombinable(system);
        if (combinable.length !== 0 || notCombinable.length !== 0) {
          return {
            ...system,
            periods: { combinable: combinable, notCombinable: notCombinable },
          };
        }
        return null;
      }),
      isNil,
    ),
  );

  const currentMaintenanceSystemPeriods: {
    combinable?: Period[];
    notCombinable?: Period[];
  } = find(processedData, { id: parseInt(watchMaintenanceSystem) })?.periods || {};

  const areNoncombinableMaintenancePeriod =
    currentMaintenanceSystemPeriods?.notCombinable && currentMaintenanceSystemPeriods?.notCombinable?.length > 0;
  const areCombinableMaintenancePeriod = currentMaintenanceSystemPeriods?.combinable && currentMaintenanceSystemPeriods?.combinable?.length > 0;

  useEffect(() => {
    reset();
  }, [watchMaintenanceSystem]);

  useEffect(() => {
    setValue('is_combinable_maintenance_period', areCombinableMaintenancePeriod);

    if (watchMileage && selectedMaintenance?.fixed_interval) {
      const diffInMonths = differenceInMonths(new Date(), new Date(initialRegistrationDate));
      const suggestion = [...selectedMaintenance?.periods]?.reverse()?.find((period) => watchMileage >= period?.km || diffInMonths >= period?.months);

      if (areCombinableMaintenancePeriod) {
        clearErrors('maintenance_period_ids_combinable');
        setValue('maintenance_period_ids_combinable', suggestion ? [suggestion.id] : []);
      } else {
        setValue('maintenance_period_ids_not_combinable', suggestion?.id);
      }
      setSuggestedPeriod(true);
      setDisplayedSuggestion(suggestion?.name);
      // trigger();
    }
  }, [watchMileage, selectedMaintenance]);

  useEffect(() => {
    const selectedMaintenanceSystem = maintenanceSystemData?.find(
      (maintenanceSystemData) => maintenanceSystemData.id === parseInt(watchMaintenanceSystem),
    );
    setSelectedMaintenance(selectedMaintenanceSystem);
  }, [watchMaintenanceSystem, maintenanceSystemData]);

  useEffect(() => {
    setSuggestedPeriod(false);
    setDisplayedSuggestion('');
    reset({
      maintenance_system_id: watchMaintenanceSystem || null,
      maintenance_period_ids_combinable: [find(currentMaintenanceSystemPeriods.combinable, { suggested: true })?.id] || [],
    });
  }, [watchMaintenanceSystem]);

  // New useEffect to re-validate mileage field

  const hasMounted = React.useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      if (watchMileage !== null && selectedMaintenance) {
        trigger('mileage');
      }
    } else {
      hasMounted.current = true;
    }
  }, [watchMileage, trigger]);

  useEffect(() => {
    if (processedData.length === 1 && watch('maintenance_system_id') === null) {
      setValue('maintenance_system_id', processedData[0].id.toString());
    }
  });

  const handleCheckboxChange = (value: string) => {
    const currentValues = watch_maintenance_period_ids_combinable || [];
    const newValues = currentValues.includes(value) ? currentValues.filter((v) => v !== value) : [...currentValues, value];
    setValue('maintenance_period_ids_combinable', newValues);
    trigger('maintenance_period_ids_combinable');
  };

  const storeInspecitonPlanMutation = useStoreInspectionPlanMutation(vin);

  const onSubmit: SubmitHandler<ISelectInspectionForm> = (formData) => {
    const combined = get(formData, 'maintenance_period_ids_combinable', []);
    const notCombined = get(formData, 'maintenance_period_ids_not_combinable', []);
    const totalPeriodsSelected = compact(combined.concat(notCombined));
    let periods = [];
    totalPeriodsSelected.forEach((period) => {
      periods.push({
        [period]: {
          name: maintenanceSystemData
            ?.find((c) => parseInt(c.id) === parseInt(formData?.maintenance_system_id))
            ?.periods.find((o) => o.id === parseInt(period))?.name,
        },
      });
    });

    const preparedData = {
      mileage: formData.mileage,
      maintenance_system_id: parseInt(formData.maintenance_system_id as string),
      maintenance_period_ids: (formData.maintenance_period_ids_combinable.length === 0 ||
      (formData.maintenance_period_ids_combinable.length === 1 && formData.maintenance_period_ids_combinable[0] === undefined)
        ? [formData.maintenance_period_ids_not_combinable]
        : formData.maintenance_period_ids_combinable
      ).filter((id) => id !== null && id !== undefined),
      maintenance_data_strings: JSON.stringify({
        [formData?.maintenance_system_id]: {
          name: processedData.find((c) => c.id === formData?.maintenance_system_id)?.name,
          periods: periods,
        },
      }),
    };

    storeInspecitonPlanMutation.mutate(preparedData, {
      onSuccess: (data) => {
        posthog.capture('generated_inspection_plan');
        setStep(1);
        setInspectionPlandId(data?.data?.id);
      },
      onError: (error: any) => {
        manageErrorResponse({ toastInstance: toast, error: error.response, t });
      },
    });
  };

  return (
    <Container as="form" onSubmit={handleSubmit(onSubmit)} data-test-id="inspection-select-form" maxWidth={'unset'} m={0}>
      <Stack>
        {maintenanceSystemQuery.isFetching ? (
          <>
            <Skeleton height="40px" style={{ marginTop: 40 }} />
            <Skeleton height="40px" style={{ marginTop: 40 }} />
          </>
        ) : maintenanceSystemData?.length === 0 ? (
          <Text>{t('forms:ip.no_plan_available')}</Text>
        ) : (
          <>
            {processedData.length === 0 ? (
              <Stack spacing={3} mt={30}>
                <Alert status="warning">
                  <AlertIcon />
                  {t('forms:ip.no_ip_available')}
                </Alert>
              </Stack>
            ) : (
              <>
                {processedData.length !== 1 && (
                  <>
                    <FormControl style={{ marginTop: 10 }}>
                      <FormLabel>
                        {t('forms:maintenance_system_id.label')}
                        <Text as="span" color="black">
                          *
                        </Text>
                      </FormLabel>
                      <Tooltip
                        placement={'top'}
                        label={t('forms:ip.single_inspection_type')}
                        shouldWrapChildren
                        isDisabled={processedData.length !== 1}
                      >
                        <Controller
                          name={'maintenance_system_id'}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              w={'full'}
                              placeholder={t('forms:maintenance_system_id.placeholder')}
                              value={processedData.length === 1 ? processedData[0].id.toString() : value?.toString()}
                              data-test-id="select-maintenance-system"
                              onChange={onChange}
                              isDisabled={processedData.length === 1}
                            >
                              {processedData?.map((inspectionPlan, index: number) => (
                                <option key={index} value={inspectionPlan?.id.toString()}>
                                  {inspectionPlan?.name}
                                </option>
                              ))}
                            </Select>
                          )}
                        />
                      </Tooltip>
                    </FormControl>
                    <Text fontSize="sm" color={'error'}>
                      {get(errors, 'maintenance_system_id', {})?.message}
                    </Text>
                  </>
                )}

                <Flex w={'100%'} style={{ marginTop: 30, marginBottom: 20 }} justify={'flex-start'}>
                  <VStack w={'50%'} alignItems={'flex-start'} minHeight={90}>
                    <Stack w={'100%'}>
                      {numberInput({
                        disabled: !watchMaintenanceSystem,
                        name: 'mileage',
                        label: t('forms:mileage.label'),
                        register,
                        placeholder: '125000',
                        errors,
                        schema,
                        required: true,
                      })}
                    </Stack>
                  </VStack>
                </Flex>

                <HStack alignItems={'start'} spacing={4}>
                  {areNoncombinableMaintenancePeriod && (
                    <Box paddingBlock={4}>
                      <HStack mt={-7}>
                        <Text fontSize={15} mb={2}>
                          {t('forms:ip.interval')}*
                        </Text>
                      </HStack>

                      {suggestedPeriod && watchMileage && initialRegistrationDate ? (
                        <Text fontSize={14}>
                          {t('forms:ip.interval_suggestion_message', {
                            interval: displayedSuggestion,
                          })}
                        </Text>
                      ) : null}
                      <Text color={'error'} fontSize={15}>
                        {get(errors, 'maintenance_period_ids_not_combinable', {})?.message}
                      </Text>
                      <Controller
                        name={'maintenance_period_ids_not_combinable'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            disabled={!watchMileage}
                            marginTop={suggestedPeriod ? 2 : 0}
                            placeholder={selectedMaintenance?.fixed_interval ? t('forms:ip.enter_mileage') : t('forms:ip.select')}
                            value={value?.toString() || ''}
                            data-test-id="select-not-combinable"
                            onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                              onChange(evt);
                              clearErrors('maintenance_period_ids_not_combinable');
                            }}
                          >
                            {get(currentMaintenanceSystemPeriods, 'notCombinable', []).map((option: Period, optionIndex: number) => (
                              <option
                                key={optionIndex}
                                data-test-id={`not-combinable-${option?.id}`}
                                value={option?.id?.toString()}
                                selected={value === option?.id}
                              >
                                {option?.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    </Box>
                  )}

                  {areCombinableMaintenancePeriod && (
                    <FormControl>
                      <FormLabel mb={5} mt={-5}>
                        {t('forms:ip.inspections')}*
                      </FormLabel>
                      {suggestedPeriod && watchMileage && initialRegistrationDate && displayedSuggestion ? (
                        <FormLabel fontSize={14} mb={8} mt={-3}>
                          {t('forms:ip.interval_suggestion_message', {
                            interval: displayedSuggestion,
                          })}
                        </FormLabel>
                      ) : null}
                      {!selectedMaintenance?.fixed_interval && <Text fontSize={14}>{t('forms:ip.no_interval_message')}</Text>}
                      <Controller
                        name="maintenance_period_ids_combinable"
                        control={control}
                        disabled={true}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CheckboxGroup value={field.value} style={{ marginTop: -10 }}>
                            <Stack direction="column" spacing={3}>
                              <Text fontSize="sm" color={'error'}>
                                {get(errors, 'maintenance_period_ids_combinable', {})?.message}
                              </Text>
                              {currentMaintenanceSystemPeriods?.combinable?.map((option) => (
                                <Checkbox
                                  disabled={!watchMileage}
                                  size={'xl'}
                                  key={option.id}
                                  value={option.id}
                                  isChecked={field.value.includes(option.id)}
                                  onChange={() => handleCheckboxChange(option.id)}
                                >
                                  <Text color="emphasized" fontWeight="medium" fontSize="sm">
                                    {option?.name}
                                  </Text>
                                </Checkbox>
                              ))}
                            </Stack>
                          </CheckboxGroup>
                        )}
                      />
                    </FormControl>
                  )}
                </HStack>
              </>
            )}
          </>
        )}
      </Stack>

      <Flex pt="10" pb="5" justifyContent={'flex-end'}>
        <Button
          p={2}
          mr={5}
          onClick={() => {
            navigate(`/app/w/vehicle?vin=${vin}`);
            window.location.reload();
          }}
          variant={'outline'}
          data-test-id="vehicle-details-back-button"
          flexShrink={0}
          color="error"
          borderColor="error"
          _hover={{ bgColor: 'error', color: 'white' }}
        >
          {t('common:abort')}
        </Button>
        <Button
          variant="primary"
          data-test-id="inspection-select-submit-button"
          type="submit"
          isLoading={storeInspecitonPlanMutation.isLoading}
          disabled={processedData.length === 0 || (submitCount > 0 && Object.keys(errors).length > 0) || storeInspecitonPlanMutation.isLoading}
        >
          {t('common:create')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
      </Flex>
    </Container>
  );
};

export default SelectInspectionStep;
