import * as Sentry from '@sentry/react';
import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';
import { WorkshopData } from '../workshop/useWorkshopQuery';

async function getPreregistratedWorkshop(registration_form_key: string | undefined): Promise<WorkshopData> {
  const { data } = await axios.get(`${config.spfApiBaseUrl}/workshop/${registration_form_key}`);
  return data;
}

export default function usePreregistratedWorkshopQuery(registration_form_key: string | undefined): {
  data?: WorkshopData;
  isFetched: boolean;
  isFetching: boolean;
  isLoading: boolean;
} {
  const navigate = useNavigate();

  return useQuery(getKey(registration_form_key), () => getPreregistratedWorkshop(registration_form_key), {
    enabled: !!registration_form_key,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: (error) => {
      Sentry.captureException(error, {
        extra: { registration_form_key },
      });
      navigate('/complete-registration/error', { replace: true });
    },
  });
}

function getKey(registration_form_key: string | undefined): QueryKey {
  return ['PreregistratedWorkshop', registration_form_key];
}

usePreregistratedWorkshopQuery.getKey = getKey;
