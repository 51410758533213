import './i18n-config';

import NiceModal from '@ebay/nice-modal-react';
import { queryClient } from 'configs/queryClient';
import VerificationFormSpf from 'features/account-setup/verification/spf-customer/VerificationFormSpf';
import PreregistrationErrorPage from 'layout/errors/PreregistrationErrorPage';
import PostHogPageView from 'posthog/PostHogPageView';
import React from 'react';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, Route, useLocation } from 'react-router-dom';
import TrackedRoute from 'posthog/TrackedRoute';

import { ProtectedRoute } from './components/ProtectedRoute';
import { SentryRoutes } from './configs/sentry';
import PreregistrationCompletedPage from './features/account-setup/preregistration/PreregistrationCompletedPage';
import PreregistrationPage from './features/account-setup/preregistration/PreregistrationPage';
import RegistrationPage from './features/account-setup/registration/RegistrationPage';
import AuthenticatedRouter from './features/AuthenticatedRouter';
import Page404 from './features/Page404';
import VersionHint from './features/VersionHint';
import ErrorPage from './layout/errors/ErrorPage';

export default function App() {
  ReactGA.initialize('G-M5CZ1X3W6L');

  return (
    <QueryClientProvider client={queryClient}>
      <NiceModal.Provider>
        <Router />
      </NiceModal.Provider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

function Router() {
  const location = useLocation();

  return (
    <div className="App">
      <PostHogPageView />
      <SentryRoutes location={location} key={location.pathname}>
        <Route index element={<Navigate to="/app" />} />

        <Route path="/" element={<Navigate to="/app" />} />

        <Route path="/login" element={<Navigate to="/app" />} />
        <Route
          path="/verification-upload/:verification_key"
          element={
            <TrackedRoute>
              <VerificationFormSpf />
            </TrackedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <TrackedRoute track>
              <RegistrationPage />
            </TrackedRoute>
          }
        />
        <Route
          path="/signup/:workshop_id/:registration_key"
          element={
            <TrackedRoute track>
              <RegistrationPage />
            </TrackedRoute>
          }
        />
        <Route
          path="/complete-registration/:registration_key"
          element={
            <TrackedRoute>
              <PreregistrationPage />
            </TrackedRoute>
          }
        />
        <Route
          path="/complete-registration/done"
          element={
            <TrackedRoute>
              <PreregistrationCompletedPage />
            </TrackedRoute>
          }
        />

        {/* ALL ROUTES THAT REQUIRE AUTHENTICATION, ARE NESTED INSIDE /app*/}
        <Route
          path="app/*"
          element={
            <TrackedRoute track>
              <ProtectedRoute component={AuthenticatedRouter} />
            </TrackedRoute>
          }
        />
        <Route path="version" element={<VersionHint />} />
        <Route
          path="complete-registration/error"
          element={
            <TrackedRoute>
              <PreregistrationErrorPage />
            </TrackedRoute>
          }
        />
        <Route
          path="error"
          element={
            <TrackedRoute track>
              <ErrorPage />
            </TrackedRoute>
          }
        />
        <Route
          path="404"
          element={
            <TrackedRoute track>
              <Page404 />
            </TrackedRoute>
          }
        />
        <Route
          path="*"
          element={
            <TrackedRoute track>
              <Page404 />
            </TrackedRoute>
          }
        />
      </SentryRoutes>
    </div>
  );
}
