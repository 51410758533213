import { Box, Center, Grid, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import { delay } from 'lodash';
import Lottie from 'lottie-react';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import alexThumbsUp from '../../../assets/animations/alex-thumbs-up.json';
import Confetti from '../../../components/Confetti';
import { useUserContext } from '../../../context/UserContextProvider';
import { eventTrack } from '../../../helpers/analytics';
import { Card } from '../../../layout/Card';

export const RegistrationCompletedPage: React.FC<{}> = () => {
  const posthog = usePostHog();
  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';
  const [sendPostHogEvent, setSendPostHogEvent] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userContext = useUserContext();
  const workshopPlanId = userContext.workshop?.subscription?.plan?.id;
  const workshopBillingCycle = userContext.workshop?.subscription?.billing_cycle;
  const accountCreated = workshopPlanId && workshopBillingCycle;

  useEffect(() => {
    delay(() => {
      let planTariffName = '';
      if (workshopBillingCycle === 'month') {
        if (workshopPlanId === 1) {
          planTariffName = 'ppu';
        } else if (workshopPlanId === 3) {
          planTariffName = 'oestarter_monthly';
        } else if (workshopPlanId === 5) {
          planTariffName = 'oe5_monthly';
        } else if (workshopPlanId === 7) {
          planTariffName = 'oe10_monthly';
        }
      } else if (workshopBillingCycle === 'year') {
        if (workshopPlanId === 3) {
          planTariffName = 'oestarter_yearly';
        } else if (workshopPlanId === 5) {
          planTariffName = 'oe5_yearly';
        } else if (workshopPlanId === 7) {
          planTariffName = 'oe10_yearly';
        }
      }

      if (accountCreated) {
        eventTrack(`${planTariffName}`);
        eventTrack('account_registration');
      }

      navigate('/app/setup/onboarding');
    }, 5000);
  }, [accountCreated]);

  if (sendPostHogEvent) {
    posthog.capture('signup_completed_page_pageview');
    setSendPostHogEvent(false);
  }

  const paddingToAlexAnimation = useBreakpointValue({
    base: '-50',
    sm: '-50',
    md: '-50',
    lg: '-70',
    xl: '-70',
    '2xl': '-70',
  });

  return (
    <PublicPageLayout abTestNewDesign={abTestNewDesign}>
      <Grid
        w="full"
        h="full"
        placeItems="center"
        height={abTestNewDesign ? '100vh' : undefined}
        backgroundColor={abTestNewDesign ? 'gray.200' : undefined}
        mt={abTestNewDesign ? paddingToAlexAnimation : null}
      >
        <Card width={'60%'} textAlign="center" minWidth={'320'}>
          <Box data-test-id="setup-completed-content" p={10}>
            <Heading size={'md'}>{t('workshop:registration.completed.title')}</Heading>
            <Text px={10} color="muted" fontSize="sm">
              {t('workshop:registration.completed.text')}
            </Text>
            <Center>
              <Box w={'60%'}>
                <Lottie animationData={alexThumbsUp} loop={true} />
              </Box>
            </Center>
          </Box>
        </Card>
      </Grid>
      <Confetti pos="fixed" bottom="0" left="15%" w={'70%'} h={'100%'} />
    </PublicPageLayout>
  );
};

export default RegistrationCompletedPage;
