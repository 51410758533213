import { Box, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import { flatten, forEach, get, has, isEmpty, isNil, isNumber, isObject, isString, map, omitBy, property, values } from 'lodash';
import * as React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { LooseObject, PartialStateCode } from '../api/types';
import IndentionBox from '../components/form-elements/IndentionBox';
import { DropdownOption, DropdownOptionGrouped } from '../components/Table/Filter';
import { useUserContext } from '../context/UserContextProvider';
import { Field, Group } from '../features/serviceRecord/MakeSpecificDataStep';
import { useChargeBeeVatValidation } from '../hooks/queries/useChargeBee';
import dateTransformer from './dateTransformer';
import { dateInput, dropdown, dropzone, numberInput, simpleCheckBox, simpleInput, textareaInput, typingDateInput } from './makeFormFields';
import config from 'config';

const formfieldsConfig = {
  sizes: {
    icon: 10,
    checkbox: 'xl',
  },
};
export type SendRequestProps = {
  url: string;
  method?: string;
  dataForEndpoint?: object;
  disableInvalidate?: boolean;
};
export const getKeyByValue = (object: PartialStateCode<string> = {}, value: any) => {
  if (isEmpty(object)) {
    return undefined;
  }
  return Object.keys(object).find((key) => object[key] === value);
};
export const getStatus = (statusCodes: object, status: string) => {
  return parseInt(getKeyByValue(statusCodes, status) as string);
};
export const getReason = (reasonCodes: PartialStateCode<number>, reasonParent: number, reason: string) => {
  const reasonObject = reasonCodes[reasonParent] || {};
  //@ts-ignore
  return parseInt(getKeyByValue(reasonObject, reason));
};
export const getArrayForGroupedDropdown = (options: any) => {
  return values(omitBy(flatten(map(options, property('options'))), isNil));
};
export const getVehicleTypeOptions = (props: {
  vehicleTypes: Array<any>;
  initialOptions?: DropdownOptionGrouped;
  t: any;
}): Array<DropdownOptionGrouped> => {
  const { vehicleTypes = [], t } = props;
  let returnArray: DropdownOptionGrouped[] = [];
  const vehicleTypesGroupedByModelFuelType: LooseObject[] = [];
  vehicleTypes.forEach((vehicleType) => {
    vehicleTypesGroupedByModelFuelType.push({
      // [`${vehicleType.name} / ${vehicleType.fuel_type}`]: vehicleType,
      [`${vehicleType.name}`]: vehicleType,
    });
  });

  const vehicleTypesGrouped: LooseObject = vehicleTypesGroupedByModelFuelType.reduce((acc, obj) => {
    //@ts-ignore
    const key: string = Object.keys(obj);

    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(values(obj)[0]);
    return acc;
  }, {});

  forEach(Object.keys(vehicleTypesGrouped), (vehicleTypeKey) => {
    let vehicleTypeOptions: DropdownOption[] = [];
    forEach(vehicleTypesGrouped[vehicleTypeKey], (value) => {
      vehicleTypeOptions.push({
        label: `${value.engine_code ? ` ${value.engine_code}` : ''}${value.output ? ` / ${value.output}` : ''}`,
        value: value['hp_id'],
      });
    });

    returnArray.push({
      label: vehicleTypeKey,
      options: vehicleTypeOptions,
    });
  });

  return returnArray;
};

export const createDropdownStaticOptionsWithMutator = (props: {
  records: Array<any>;
  mutator: (value: any) => any;
  initialOptions?: DropdownOption;
  t: any;
}): DropdownOption[] => {
  const { records = [], mutator, t } = props;
  let returnArray: DropdownOption[] = [];

  forEach(records, (value) => {
    returnArray.push({
      label: mutator(value),
      value: value,
    });
  });
  return returnArray;
};

export const createDropdownBasedOnTwoDepsFields = (deps: Array<string>) => {
  const firstMultiplier = parseInt(deps[0]);
  const secondMultiplier = parseInt(deps[1]);

  const userContext = useUserContext();
  const country = userContext.workshop?.country;
  const units = userContext.workshop?.units;
  const language = userContext.workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;
  let returnArray: object[] = [];

  forEach([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (value) => {
    returnArray.push({
      label: `${firstMultiplier * value}. ${getI18n().t('forms:year.label')} / ${(secondMultiplier * value).toLocaleString(
        localeParams,
      )} ${units === 'metric' ? 'km' : 'mi'}`,
      value: `${firstMultiplier * value}/${secondMultiplier * value}`,
    });
  });
  return returnArray;
};

export const createDropdownStaticOptionsByDoubleValue = (firstMultiplier: number, secondMultiplier: number, t: any) => {
  const userContext = useUserContext();
  const country = userContext.workshop?.country;
  const units = userContext.workshop?.units;
  const language = userContext.workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;
  let returnArray = [];

  forEach([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (value) => {
    returnArray.push({
      label: `${firstMultiplier * value}. ${t('forms:year.label')} / ${(secondMultiplier * value).toLocaleString(
        localeParams,
      )} ${units === 'metric' ? 'km' : 'mi'}`,
      value: `${firstMultiplier * value}/${secondMultiplier * value}`,
    });
  });
  return returnArray;
};

export const createDropdownPlainOptions = (props: {
  records: Array<any>;
  valueColumn: string;
  labelColumn: string;
  initialOptions?: DropdownOption;
  t: any;
}): Array<DropdownOption> => {
  const { records = [], valueColumn, labelColumn, t, initialOptions = { label: t('common:choose'), value: '' } } = props;
  let returnArray: DropdownOption[] = [];

  if (initialOptions) {
    returnArray.push(initialOptions);
  }

  forEach(records, (value) => {
    returnArray.push({
      label: value[labelColumn],
      value: value[valueColumn],
    });
  });
  return returnArray;
};

export const displayRow = (props: {
  label?: string | React.ReactElement;
  content?: any;
  contentOnNewLine?: boolean;
  isLoading?: boolean;
  isForOverview?: boolean;
  fontSize?: string;
  customClass?: string;
  appendUnits?: boolean;
}) => {
  let Wrapper = HStack;
  const { contentOnNewLine, content, label, isLoading, isForOverview = false, fontSize = 'xs', customClass = '', appendUnits } = props;
  if (contentOnNewLine) {
    Wrapper = Stack;
  }

  const userContext = useUserContext();
  const country = userContext.workshop?.country;
  const units = userContext.workshop?.units;
  const language = userContext.workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;
  if (isLoading) {
    return <Skeleton height="40px" />;
  }
  return (
    <Wrapper justifyContent={isForOverview ? 'space-between' : undefined} className={customClass}>
      {label && (
        <Flex alignItems={'center'} style={{ textDecoration: 'none' }}>
          <Text as="div" fontSize={fontSize} fontWeight="semibold">
            {label}
          </Text>
          <Text as="div" fontSize={fontSize} fontWeight="semibold">
            {content ? ':' : ''}
          </Text>
        </Flex>
      )}
      <Text as="div" fontSize={fontSize}>
        {appendUnits ? `${content?.toLocaleString(localeParams)} ${units === 'metric' ? 'km' : 'mi'}` : content}
      </Text>
    </Wrapper>
  );
};

export const simpleRenderValue = (value: any, timezone: string | undefined) => {
  if (isNumber(value) || isString(value)) {
    return value;
  }
  return dateTransformer(value, timezone);
};

export const renderValue = (value: any, timezone: string | undefined, field: Field | LooseObject = {}, t: any) => {
  const { display } = field;
  if (isObject(value) && has(value, 'file')) {
    // @ts-ignore
    return value.file.name;
  }
  if (isNumber(value)) {
    return value;
  }
  if (['date', 'type-date']?.includes(display?.type)) {
    //dates will make the view crash, so they need to be formatted
    return dateTransformer(value, timezone);
  }
  if (typeof value == 'boolean' || display?.type === 'checkbox') {
    //we only display checked values so they need to be true
    return t('common:yes');
  }
  if (display?.type === 'dropdown' && field?.type === 'string') {
    if (value?.includes('/')) {
      const chunks = value.split('/');
      return `${chunks[0]}. ${t('forms:year.label')} / ${chunks[1].toLocaleString('de-de')} km`;
    } else {
      return field?.display?.options.find((obj: DropdownOption) => obj.value === value)?.label;
    }
  }
  return value;
};
export const simulateMouseClick = (element: HTMLElement | null) => {
  if (!element) {
    return null;
  }
  element.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1,
    }),
  );
};

/**
 * based on the form schema returned by the API
 * it creates a yup validation schema for make specific fields
 *
 * @return a yup Schema
 * */
export const buildValidationSchema = (groups: Group[]) => {
  let formValidationObject = {};

  // Iterate through each group and field, and create validation rules for fields with validations
  groups?.forEach((group: Group) => {
    group?.fields.forEach((field: Field) => {
      const hasValidations = field?.validations && Object.keys(field.validations).length > 0;
      if (hasValidations) {
        // Create validation rules for the current field
        const fieldValidationRules = createYupField(formValidationObject, field);
        formValidationObject = { ...formValidationObject, ...fieldValidationRules };

        // Create validation rules for each child field
        if (field.children) {
          field.children.forEach((child: Field) => {
            const hasChildValidations = child?.validations && Object.keys(child.validations).length > 0;
            if (hasChildValidations) {
              const childValidationRules = createYupField(formValidationObject, child);
              formValidationObject = { ...formValidationObject, ...childValidationRules };

              // Create validation rules for each grandchild field
              if (child?.children) {
                child?.children?.forEach((childChild: Field) => {
                  const hasGrandchildValidations = childChild?.validations && Object.keys(childChild.validations).length > 0;
                  if (hasGrandchildValidations) {
                    const grandchildValidationRules = createYupField(formValidationObject, childChild);
                    formValidationObject = {
                      ...formValidationObject,
                      ...grandchildValidationRules,
                    };
                  }
                });
              }
            }
          });
        }
      }
    });
  });
  return yup.object(formValidationObject).required();
};

export const extractGroupFieldNames = (group: Group) => {
  let names: string[] = [];

  group?.fields.forEach((field: Field) => {
    names.push(field.name);
    if (field?.children) {
      field?.children.forEach((child: Field) => {
        names.push(child.name);
      });
    }
  });

  return names;
};

/**
 * based on the data of each field, creates the validation for 1 field
 * @return a yup field validation
 * */
function createYupField(schema: LooseObject, field: Field) {
  const { name, label, validations = [] } = field;
  const { t } = useTranslation();

  let { type } = field;

  if (type === 'integer') {
    type = 'number';
  }
  if (type === 'file') {
    type = 'object';
  }

  //@ts-ignore
  if (!yup[type]) {
    return schema;
  }
  //@ts-ignore
  let validator = yup[type]();

  Object.keys(validations).forEach((validationKey) => {
    //change for new format
    const {
      deps = [],
      params = {},
      errors = {},
    }: {
      deps: string[];
      errors?: LooseObject;
      params?: LooseObject;
    } = validations[validationKey];

    switch (validationKey) {
      case 'required':
        validator = validations[validationKey] ? validator.required() : validator.nullable();
        break;
      case 'when':
        validator = validator.when(deps[deps.length - 1], {
          is: (val: any) => {
            const checkIsnt = 'isnt' in params;
            const checkIsBoolean = type == 'boolean';
            const valueToCompare = checkIsnt ? params?.isnt : params?.is;

            return checkIsnt
              ? (checkIsBoolean ? Boolean(valueToCompare) : valueToCompare) !== val
              : (checkIsBoolean ? Boolean(valueToCompare) : valueToCompare) === val;
          },
          then:
            params?.then === 'required' //@ts-ignore
              ? yup[type]()
                  .typeError(errors?.type_error || label)
                  .label(label)
                  .required()
              : params.then,
        });
        break;
      case 'min':
        // validator from yup-password package to validate it according to validations params from BE in the first argument
        // second argument is the error message that combines the label of the field with the validation message set in forms json file
        validator = validator.min(
          validations[validationKey],
          label +
            t('forms:validation.min', {
              min: validations[validationKey],
            }),
        );
        break;
      case 'max':
        validator =
          validator?.type !== 'object'
            ? validator?.max(
                validations[validationKey],
                label +
                  t('forms:validation.max', {
                    max: validations[validationKey],
                  }),
              )
            : validator.nullable();
        break;
      // case 'max':
      //   validator = validator[validationKey](...Object.values(params)).typeError(
      //     errors?.type_error || label,
      //   );
      //   break;
    }
  });
  schema[name] = validator;
  return schema;
}

/**
 * based on the form schema returned by the API
 * it creates the form fields for a section
 * @return  JSX.Element
 * */
export const buildForm = (formSchema: { id: number; groups: Group[] }, params: LooseObject) => {
  const groups = get(formSchema, 'groups', []);
  const { t } = useTranslation();
  return (
    <>
      {groups.map((group: Group, index: number) => {
        const { icon, label, fields = [] } = group;
        return (
          <Box key={`group-${index}`}>
            <HStack>
              <Image ml={2} boxSize={formfieldsConfig.sizes.icon} src={icon} />,
              <Text fontSize="lg" fontWeight="medium" mb={2}>
                {label}
              </Text>
            </HStack>
            <IndentionBox
              size={'lg'}
              onClick={() => {
                return params.setCustomErrorMessage('');
              }}
            >
              {fields.map((field: Field, fieldIndex: number) => {
                if (index === 0) {
                }
                return buildField(field, fieldIndex, params);
              })}
            </IndentionBox>
          </Box>
        );
      })}
    </>
  );
};

/**
 * it creates the form fields and its children / children-children
 * @return  JSX.Element
 * */
function buildField(field: Field, fieldIndex: number, params: LooseObject) {
  const fieldChildren = get(field, 'children', []);
  return (
    <>
      <Box>{buildInput(field, params, fieldIndex)}</Box>
      {fieldChildren.length > 0 ? (
        <Box key={`children-box-${fieldIndex}`}>
          {fieldChildren.map((child: Field, childIndex: number) => {
            const childChildren: Field[] = get(child, 'children', []);
            return (
              <Box key={`child-${childIndex}`} m={2}>
                {buildInput(child, params, childIndex)}
                {childChildren.length > 0
                  ? childChildren.map((childChild, childChildIndex) => buildInput(childChild, params, childChildIndex))
                  : null}
              </Box>
            );
          })}
        </Box>
      ) : null}
    </>
  );
}

/**
 * it creates the form input only for a field using a makeFormField helper
 * @return  JSX.Element
 * */
function buildInput(field: Field, params: LooseObject, fieldIndex: number) {
  const helper = getFieldBasedOnType(field?.display?.type);
  const deps = field?.display?.deps || [];
  const dependencyValues = deps.length > 0 ? params?.watch(deps) : [true];

  let exactValue = true;
  if (
    'when' in field?.validations &&
    (!['true', 'false'].includes(field?.validations?.when?.params?.is) || 'isnt' in field?.validations?.when?.params)
  ) {
    const validationDeps = field?.validations?.when?.deps || [];

    const dependencyValue = dependencyValues[deps.indexOf(validationDeps[validationDeps.length - 1])];

    if (dependencyValue) {
      if ('isnt' in field?.validations?.when?.params) {
        exactValue = dependencyValue != field?.validations?.when?.params?.isnt;
      } else {
        exactValue = dependencyValue == field?.validations?.when?.params?.is;
      }
    }
  }

  if (dependencyValues.every(Boolean) && exactValue) {
    return (
      <Box key={`field-${fieldIndex}`}>
        {helper({
          ...field,
          ...params,
          ...field?.display,
          required: field?.type === 'boolean' ? false : field?.validations?.required === true,
          deps: dependencyValues,
        })}
      </Box>
    );
  }
  return null;
}
/**
 * mapping of input types and makeFormField helpers
 * */
export function getFieldBasedOnType(type: string) {
  const typeMapping = {
    checkbox: simpleCheckBox,
    dropdown: dropdown,
    number: numberInput,
    string: simpleInput,
    textarea: textareaInput,
    file: dropzone,
    date: dateInput,
    'type-date': typingDateInput,
  };
  // @ts-ignore
  return typeMapping[type];
}

/**
 * mapping of custom schema methods and makeFormField helpers
 * */
export function getCustomMethodFormFormField(type: string) {
  const typeMapping = {
    createDropdownStaticOptionsByDoubleValue: createDropdownBasedOnTwoDepsFields,
  };
  // @ts-ignore
  return typeMapping[type];
}

export const validateVat = async (vat_number: string, country?: string) => {
  if (isNil(vat_number) || vat_number === '' || isNil(country)) {
    return true;
  }

  return await useChargeBeeVatValidation(country, vat_number);
};

export const useIsWorkshopBlocked = () => {
  const userContext = useUserContext();
  return userContext.workshop?.status === 800;
};

   // for CZ the first replace trim all the spaces. the second one adds a space after every 3 digits
   // for GB the first replace trim all the spaces. the second one adds a space before last three digits
   // for the rest the first replace trim all the spaces. the second one adds a space before last three digits
export const formatZipcode = (val: string, country: string) => {
  if (country === 'CZ') {
    return (val.replace(/ /g, '')).replace(/\d{3}(?=.)/g, '$& ');
  } else if (country === 'GB') {
    return (val.replace(/ /g, '')).slice(0, -3) + ' ' + (val.replace(/ /g, '')).slice(-3);
  } else if (country === 'NL' || country === 'SE' || country === 'SK') {
    return (val.replace(/ /g, '')).slice(0, -2) + ' ' + (val.replace(/ /g, '')).slice(-2);
  } else return val.replace(/ /g, '');
}

export function isWithinTwoWeeks(registeredAt: string): boolean {
  const registeredDate = new Date(registeredAt);
  const currentDate = new Date();
  const twoWeeksInMilliseconds = config.posthog.recording_period * 24 * 60 * 60 * 1000;

  return currentDate.getTime() - registeredDate.getTime() <= twoWeeksInMilliseconds;
}