export default {
  appEnv: import.meta.env.VITE_APP_ENV || 'local',
  appTitle: import.meta.env.VITE_APP_TITLE || 'oe service Customer Portal',
  authDomain: import.meta.env.VITE_AUTH0_DOMAIN || '',
  authConnection: import.meta.env.VITE_AUTH0_CONNECTION || '',
  authClientId: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
  authClientSecret: import.meta.env.VITE_AUTH0_CLIENT_SECRET || '',
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
  baseUrl: import.meta.env.VITE_BASE_URL || window.location.origin,
  logoutReturnTo: import.meta.env.VITE_LOGOUT_RETURN_TO || import.meta.env.VITE_BASE_URL,
  publicWebsiteUrl: import.meta.env.VITE_PUBLIC_WEBSITE_URL || 'https://www.oeservice.eu',
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL || 'https://int.api.oeservice.eu/v2',
  cpSpfApiBaseUrl: import.meta.env.VITE_CP_SPF_API_BASE_URL || 'https://int.api.oeservice.eu/cp-spf/v1',
  spfApiBaseUrl: import.meta.env.VITE_SPF_API_BASE_URL || 'https://int.api.oeservice.eu/spf/v1',
  buildBranch: import.meta.env.VITE_BRANCH || false,
  buildCommit: import.meta.env.VITE_COMMIT_REF || '-',
  buildTag: import.meta.env.VITE_TAG || false,
  buildTime: import.meta.env.VITE_BUILD_TIME || '-',
  chargeBeeSiteId: import.meta.env.VITE_CHARGEBEE_SITE || '-',
  gtmId: import.meta.env.VITE_GTM_ID || false,
  posthog: {
    enabled: import.meta.env.POSTHOG_ENABLED || false,
    api_host_url: 'https://eu.posthog.com',
    person_profiles: 'identified_only',
    api_key: import.meta.env.VITE_POSTHOG_API_KEY || '',
    project_id : import.meta.env.VITE_POSTHOG_PROJECT_ID || '17475',
    recording_period: 14,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN || '-',
    release: import.meta.env.VITE_SENTRY_RELEASE || false,
    project_id: import.meta.env.VITE_SENTRY_PROJECT_ID,
    project_name: import.meta.env.VITE_SENTRY_PROJECT_NAME,
    organization_id: import.meta.env.VITE_SENTRY_ORGANIZATION_ID,
    organization_url: import.meta.env.VITE_SENTRY_ORGANIZATION_URL
  },
};
