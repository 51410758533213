import { Box, Button, Flex, HStack, Icon, SimpleGrid, Stack, Text, Tooltip, useBreakpointValue, useToast, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { createDropdownStaticOptionsWithMutator, validateVat, formatZipcode } from 'helpers/general';
import useCountryQuery from 'hooks/public/queries/useGeoLocationQuery';
import { use } from 'i18next';
import { get } from 'lodash';
import { postcodeValidator } from 'postcode-validator';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillCar } from 'react-icons/ai';
import { HiOutlineLightBulb, IoMdStar, IoMdStarHalf } from 'react-icons/all';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
// @ts-ignore
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import * as yup from 'yup';

import { Country, ISignupForm, Methods, PartialStateCode } from '../../../api/types';
import { PortalButton } from '../../../components/buttons/PortalButton';
import { CustomerLogoGrid } from '../../../components/CustomerLogoBar/CustomerLogoBar';
import { ServiceHistoryRequestIcon, ServiceRecordIcon } from '../../../components/Icons';
import CheckBoxDescription from '../../../components/labels/CheckBoxDescription';
import AlertModal from '../../../components/modals/AlertModal';
import { FONT_WEIGHTS } from '../../../global/Fonts';
import { formatTelephone, telephonePrefixesOptions } from '../../../helpers/localization';
import { dropdown, simpleCheckBox, simpleInput } from '../../../helpers/makeFormFields';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';

const FORMFIELD_CONFIG = {
  spacing: 2,
};

const SignupStepSplitted = (props: {
  saveInState(x: any): void;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  data: Partial<WorkshopData>;
  setStep(y: number): void;
  method?: Methods;
  url: string;
  expandForm?: boolean;
  setExpandForm?: (x: any) => void;
  localizationProps: {
    countries: string[];
    languages: { [country: string]: string[] };
    zipcodeLength: PartialStateCode<string>;
    vatFormatting: PartialStateCode<string>;
    telephonePrefixes: PartialStateCode<string>;
  };
}) => {
  const posthog = usePostHog();
  const abTestSignupFormNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' || posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  const defaultCountry: string = 'DE';
  const [sendPostHogEvent, setSendPostHogEvent] = useState(true);
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const businessType = queryParams.get('business_type');
  const {
    setUserEmail,
    saveInState,
    data,
    setStep,
    method = 'post',
    url,
    localizationProps: { countries = [], languages = {}, vatFormatting = {}, telephonePrefixes = {} },
    expandForm,
    setExpandForm,
  } = props;

  const countryDropdownOptions = createDropdownStaticOptionsWithMutator({
    records: countries,
    mutator: (val) => (i18n.exists(`location:countries.${val.toUpperCase()}`) ? t(`location:countries.${val.toUpperCase()}`) : val.toUpperCase()),
    t,
  });

  const getLanguageDropdownOptions = (country: string) => {
    if (!country) {
      return [];
    }
    return createDropdownStaticOptionsWithMutator({
      records: languages[country],
      mutator: (val) => t(`location:languages.${val.toUpperCase()}`),
      t,
    });
  };

  const countryQuery = useCountryQuery(countries);
  const browserCountry: Country = countryQuery?.data;

  const schema = yup
    .object({
      firstname: yup.string().required(t('forms:firstname.error_message')),
      lastname: yup.string().required(t('forms:lastname.error_message')),
      email: yup
        .string()
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .required(t('forms:email.error_message')),
      telephone: yup.string().required(t('forms:telephone.error_message')),
      telephone_prefix: yup.string().label(t('forms:telephone.label')).required(),
      name: yup.string().required(t('forms:name.error_message')),
      role: yup
        .string()
        .required(t('forms:role.error_message'))
        .nullable()
        .notOneOf([null, 'null'], t('forms:role.invalid'))
        .min(1, t('forms:role.min_length')),
      vat_number: yup.string().nullable().notRequired(),
      country: yup.string().oneOf(countries, t('errors:countries.valid')).required(t('forms:country.error_message')),
      language: yup.string().required(t('forms:language.error_message')),
      city: yup.string().required(t('forms:city.error_message')),
      zipcode: yup.string().test('zipcode', t('forms:zipcode.invalid'), (val = '', context) => {
        if (val && context?.parent?.country) {
          return postcodeValidator(val, context?.parent?.country === 'XI' ? 'GB' : context?.parent?.country ?? '');
        } else return false;
      }),
      address: yup.string().required(t('forms:address.error_message')),
      consent_termsandconditions: yup.bool().label(t('forms:consent_termsandconditions.label')).required().oneOf([true], t('common:required')),
      consent_dataprivacy: yup.boolean().label(t('forms:consent_dataprivacy.label')).required().oneOf([true], t('common:required')),
      consent_newsletter: yup.boolean(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setFocus,
    setValue,
    clearErrors,
    trigger,
    formState: { isValid, submitCount, errors, isValidating },
    setError,
    getValues,
  } = useForm<ISignupForm>({
    defaultValues: {
      country: undefined,
      language: undefined,
      name: '',
      vat_number: undefined,
      address: '',
      city: '',
      zipcode: undefined,
      firstname: '',
      lastname: '',
      email: '',
      telephone_prefix: '',
      telephone: '',
      consent_dataprivacy: undefined,
      consent_newsletter: false,
      consent_termsandconditions: undefined,
      distributor_id: null,
      registration_form_key: undefined,
      business_type: businessType,
      role: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const country = watch('country');
  const firstName = watch('firstname');
  const lastName = watch('lastname');
  const name = watch('name');
  const userRole = watch('role');
  const userEmail = watch('email');
  const selectedLanguage = watch('language');

  const workshopVat = watch('vat_number');
  const userRoles = [
    { value: 'owner', label: t('forms:role.owner') },
    { value: 'mechanic', label: t('forms:role.mechanic') },
    { value: 'office', label: t('forms:role.office') },
    { value: 'other', label: t('forms:role.other') },
  ];
  const moreThanOneLanguage = getLanguageDropdownOptions(country).length > 1;
  useEffect(() => {
    setValue('language', selectedLanguage);
    if (expandForm) {
      setValue('country', country);

      setValue('business_type', businessType);
      setValue('role', userRole);
    }
  }, [expandForm]);

  useEffect(() => {
    if (userRole) {
      localStorage.setItem('userRole', userRole);
    }
  }, [userRole]);

  useEffect(() => {
    // if loading the country is done the appropriate country is set from the ip address we get,
    // else we keep waiting until the loading is done and if ot's error we set deutschland as the default country
    setValue('country', !countryQuery.isError ? browserCountry?.country : defaultCountry, { shouldDirty: false });
    if (country) {
      setValue('telephone_prefix', telephonePrefixes[country]);
      const currenLanguages: string[] = get(languages, country);
      if (currenLanguages) {
        setValue('language', currenLanguages[0]);
      }
    }
  }, [countryQuery?.isLoading]);

  useEffect(() => {
    if (country) {
      setValue('telephone_prefix', telephonePrefixes[country]);

      const currenLanguages: string[] = get(languages, country);
      if (currenLanguages) {
        setValue('language', currenLanguages[0]);
      }
    }
  }, [country]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field: any, a: any) => {
      // @ts-ignore
      return errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const { mutate, isLoading } = useMutation<any, Error, ISignupForm>(async (data) => {
    const requestData = { ...data, telephone: data?.telephone_prefix + data?.telephone };
    delete requestData?.telephone_prefix;

    return await axios[method](`${url}`, requestData).catch((err: any) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
      return Promise.reject(err);
    });
  });
  const onSubmit: SubmitHandler<ISignupForm> = (formData) => {
    setUserEmail(formData.email);
    mutate(
      {
        ...formData,
        vat_number: workshopVat ? workshopVat : null,
        country: country === 'XI' ? 'GB' : country,
      },
      {
        onSuccess: (response = {}) => {
          posthog.capture('email_verification_pageview');
          saveInState({ ...formData, ...response.data?.data });
          //if email is verified, skip that step
          if (response?.data?.data?.email_verified) {
            setStep(2);
          } else {
            setStep(1);
          }
        },
        onError: (error: any) => {
          const { errors } = error.response.data;
          (Object.keys(errors) as Array<keyof ISignupForm>).forEach((key) => {
            setError(key, {
              type: 'server',
              message: errors[key]!.join('. '),
            });
          });
        },
      },
    );
  };

  const handleNextStep = async () => {
    const result = await trigger(['country', 'language', 'firstname', 'lastname', 'name', 'email', 'role']);
    if (result) {
      clearErrors();
      posthog.capture('expand_signup_form_buttonClick');
      setExpandForm(true);
    } else return;
  };

  const onChangeVat = useAsyncDebounce((val) => {
    // @ts-ignore
    if (country === 'CH' || country === 'LI') {
      if (val.length > 20) {
        setError('vat_number', {
          type: 'server',
          message: t('forms:vat_number.too_long'),
        });
      } else {
        clearErrors('vat_number');
      }
    } else {
      validateVat(val, country).then((data: boolean) => {
        // other solution is to build a custom flag, and fake the error messages on the vat input field
        if (!data) {
          setError('vat_number', {
            type: 'server',
            message: t('forms:vat_number.invalid'),
          });
          setTimeout(() => {
            setFocus('vat_number');
          }, 100);
        } else {
          clearErrors('vat_number');
        }
      });
    }
  }, 3000);

  const [vatNumber, setVatnumber] = useState('');

  useEffect(() => {
    clearErrors('language');
    clearErrors('telephone_prefix');
  }, [country]);

  watch((data) => {
    // if (data.language !== i18n.language) {
    //   i18n.changeLanguage(data.language);
    // }
  });

  // useEffect(() => {
  //   setValue('language', i18n.language);
  // }, [expandForm]);

  if (sendPostHogEvent) {
    posthog.capture('signup_form_pageview');
    setSendPostHogEvent(false);
  }
  //break vertically below size LG
  const isTablet = useBreakpointValue({ base: true, sm: true, md: true, lg: false });
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const formWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center" padding={0}>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        data-test-id="signup-form"
        px={abTestSignupFormNewDesign ? 0 : 8}
        py={4}
        width={abTestSignupFormNewDesign ? formWidth : 'full'}
      >
        {!expandForm && !isTablet && !isMobile ? (
          <HStack alignItems="start">
            <Box width={'50%'} borderRight={'1px'} borderRightColor={'gray.300'} height={'100%'}>
              <Stack spacing="5">
                {!abTestSignupFormNewDesign && (
                  <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
                    {t('workshop:registration.stepper.one.info.contact.label')}
                  </Text>
                )}

                <Box>
                  <Stack spacing="6" direction={{ base: 'column', md: 'column' }} py={FORMFIELD_CONFIG.spacing} pr={10}>
                    {dropdown({
                      name: 'country',
                      label: t('forms:country.label'),
                      control,
                      errors,
                      register,
                      options: countryDropdownOptions,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                      bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                      placeholder: countryQuery.isLoading ? t('common:loading') : undefined,
                      placeHolderColor: countryQuery.isLoading || abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      hideDropdownNoValueOption: true,
                    })}
                    {moreThanOneLanguage &&
                      dropdown({
                        name: 'language',
                        label: t('forms:language.label'),
                        control,
                        errors,
                        register,
                        options: getLanguageDropdownOptions(country),
                        hideDropdownNoValueOption: true,
                        schema,
                        customClass: 'pii',
                        disabled: getLanguageDropdownOptions(country).length < 2,
                        boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                        bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                        placeholder: abTestSignupFormNewDesign ? t('forms:language.label') : undefined,
                        placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      })}
                    {simpleInput({
                      name: 'firstname',
                      label: t('forms:firstname.label'),
                      placeholder: t('forms:firstname.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'lastname',
                      label: t('forms:lastname.label'),
                      placeholder: t('forms:lastname.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'name',
                      label: t('forms:name.label'),
                      placeholder: t('forms:name.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {dropdown({
                      name: 'role',
                      label: t('forms:role.label'),
                      control,
                      errors,
                      register,
                      schema,
                      options: userRoles,
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                      bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                      placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      placeholder: t('common:choose'),
                      hideDropdownNoValueOption: true,
                      borderRadius: '8',
                    })}
                    {simpleInput({
                      name: 'email',
                      label: t('forms:email.label'),
                      placeholder: t('forms:email.placeholder'),
                      register,
                      errors,
                      customHook: (val) => setValue('email', val.replace(/ /g, '')),
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    <Flex
                      direction="row-reverse"
                      //py="4"
                      //px={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}
                    >
                      {!expandForm && (
                        <PortalButton
                          disabled={
                            expandForm ||
                            errors?.email ||
                            errors?.role ||
                            errors?.firstname ||
                            errors?.lastname ||
                            errors?.name ||
                            errors?.country ||
                            (submitCount > 0 &&
                              !(
                                userRole !== '' &&
                                userRole !== null &&
                                userRole &&
                                userRole?.length > 0 &&
                                userEmail?.length >= 2 &&
                                country?.length > 0 &&
                                firstName?.length > 0 &&
                                lastName?.length > 0 &&
                                name?.length > 0
                              ))
                          }
                          onClick={() => {
                            handleNextStep();
                          }}
                          type={'variant'}
                          alignSelf={'flex-end'}
                          width={'full'}
                        >
                          {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
                        </PortalButton>
                      )}
                    </Flex>
                  </Stack>
                </Box>
                {/*  <Flex
                  direction="row-reverse"
                  //py="4"
                  //px={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}

                >
                  {!expandForm && (
                    <PortalButton
                      disabled={expandForm}
                      onClick={() => {
                        handleNextStep();
                      }}
                      type={'variant'}
                      alignSelf={'flex-end'}
                      width={'full'}
                    >
                      {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
                    </PortalButton>
                  )}
                </Flex>*/}
              </Stack>
            </Box>
            <Box width={'50%'} px={4}>
              <Stack spacing="5">
                <Stack pt={0} mt={0}>
                  <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} color={'blue.500'}>
                    {t('components:trust_elements.signup_step.benefits_headline')}
                  </Text>
                </Stack>

                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }} align={'center'}>
                  <SimpleGrid columns={2} width={'100%'} gap={5} mt={10}>
                    <HStack>
                      <Box pl={'4px'}>
                        <ServiceRecordIcon fill={'blue.500'} boxSize="8" alignSelf={'center'} />
                      </Box>
                      <Box pl={'8px'}>
                        <Text>{t('components:trust_elements.signup_step.manufacturer_compliant')}</Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Box mr={'5px'}>
                        <ServiceHistoryRequestIcon color={'blue.500'} boxSize="8" alignSelf={'center'} />
                      </Box>
                      <Box pl={'8px'}>
                        <Text>{t('components:trust_elements.signup_step.time_expenditure')}</Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Icon as={AiFillCar} boxSize="10" color={'blue.500'} />
                      <Box pl={'8px'}>
                        <Text>{t('components:trust_elements.signup_step.one_portal')}</Text>
                      </Box>
                    </HStack>
                    <HStack>
                      <Icon as={HiOutlineLightBulb} boxSize="10" color={'blue.500'} />
                      <Box pl={'5px'}>
                        <Text>{t('components:trust_elements.signup_step.easy_handling')}</Text>
                      </Box>
                    </HStack>
                  </SimpleGrid>
                  <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} pt={10} color={'blue.500'} pb={5}>
                    {t('components:trust_elements.signup_step.partner_logos_headline')}
                  </Text>
                  <CustomerLogoGrid />
                </Box>
              </Stack>
            </Box>
          </HStack>
        ) : !expandForm ? (
          <VStack align={'center'}>
            <Box width={isMobile ? 'full' : isTablet ? '75%' : 'full'}>
              <Stack spacing="5">
                {!abTestSignupFormNewDesign && (
                  <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
                    {t('workshop:registration.stepper.one.info.contact.label')}
                  </Text>
                )}

                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
                  <Stack
                    //spacing="6"
                    direction={{ base: 'column', md: 'column' }}
                    py={FORMFIELD_CONFIG.spacing}
                  >
                    {dropdown({
                      name: 'country',
                      label: t('forms:country.label'),
                      control,
                      errors,
                      register,
                      options: countryDropdownOptions,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                      bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                      placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      hideDropdownNoValueOption: true,
                    })}
                    {moreThanOneLanguage &&
                      dropdown({
                        name: 'language',
                        label: t('forms:language.label'),
                        control,
                        errors,
                        register,
                        options: getLanguageDropdownOptions(country),
                        hideDropdownNoValueOption: true,
                        schema,
                        customClass: 'pii',
                        disabled: getLanguageDropdownOptions(country).length < 2,
                        boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                        bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                        placeholder: abTestSignupFormNewDesign ? t('forms:language.label') : undefined,
                        placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      })}
                    {simpleInput({
                      name: 'firstname',
                      label: t('forms:firstname.label'),
                      placeholder: t('forms:firstname.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'lastname',
                      label: t('forms:lastname.label'),
                      placeholder: t('forms:lastname.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'name',
                      label: t('forms:name.label'),
                      placeholder: t('forms:name.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {dropdown({
                      name: 'role',
                      label: t('forms:role.label'),
                      control,
                      errors,
                      register,
                      schema,
                      options: userRoles,
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                      bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                      placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                      placeholder: t('common:choose'),
                      hideDropdownNoValueOption: true,
                      borderRadius: '8',
                    })}
                    {simpleInput({
                      name: 'email',
                      label: t('forms:email.label'),
                      placeholder: t('forms:email.placeholder'),
                      register,
                      errors,
                      customHook: (val) => setValue('email', val.replace(/ /g, '')),
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                  </Stack>
                </Box>
                {!expandForm && isTablet && (
                  <Box pb={10}>
                    <PortalButton
                      disabled={
                        expandForm ||
                        errors?.email ||
                        errors?.role ||
                        errors?.firstname ||
                        errors?.lastname ||
                        errors?.name ||
                        errors?.country ||
                        (submitCount > 0 &&
                          !(
                            userRole !== '' &&
                            userRole !== null &&
                            userRole &&
                            userRole?.length > 0 &&
                            userEmail?.length >= 2 &&
                            country?.length > 0 &&
                            firstName?.length > 0 &&
                            lastName?.length > 0 &&
                            name?.length > 0
                          ))
                      }
                      onClick={() => {
                        handleNextStep();
                      }}
                      type={'variant'}
                      alignSelf={'flex-end'}
                      width={'full'}
                    >
                      {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
                    </PortalButton>
                  </Box>
                )}
              </Stack>
            </Box>
            <Box width={isMobile ? 'full' : '50%'}>
              <Stack spacing="5">
                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }} align={'center'}>
                  {/*    <CustomerLogoGrid />*/}
                  <Box width={!isMobile && !isTablet ? '50%' : 'full'}>
                    <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} color={'blue.500'} pb={'5'}>
                      {t('components:trust_elements.signup_step.benefits_headline')}
                    </Text>
                  </Box>

                  {isTablet && !isMobile ? (
                    <>
                      <Box width={!isMobile && !isTablet ? '50%' : 'full'}>
                        <SimpleGrid columns={2} width={'100%'} gap={5} padding={4}>
                          <HStack>
                            <Box pl={'4px'}>
                              <ServiceRecordIcon fill={'blue.500'} boxSize="8" alignSelf={'center'} />
                            </Box>
                            <Box pl={'8px'}>
                              <Text>{t('components:trust_elements.signup_step.manufacturer_compliant')}</Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Box mr={'5px'}>
                              <ServiceHistoryRequestIcon color={'blue.500'} boxSize="8" alignSelf={'center'} />
                            </Box>
                            <Box pl={'8px'}>
                              <Text>{t('components:trust_elements.signup_step.time_expenditure')}</Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Icon as={AiFillCar} boxSize="10" color={'blue.500'} />
                            <Box pl={'8px'}>
                              <Text>{t('components:trust_elements.signup_step.one_portal')}</Text>
                            </Box>
                          </HStack>
                          <HStack>
                            <Icon as={HiOutlineLightBulb} boxSize="10" color={'blue.500'} />
                            <Box pl={'8px'}>
                              <Text>{t('components:trust_elements.signup_step.easy_handling')}</Text>
                            </Box>
                          </HStack>
                        </SimpleGrid>
                        <Stack mt={10} pb={'5'}>
                          <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} pb={5} color={'blue.500'} mt={5}>
                            {t('components:trust_elements.signup_step.partner_logos_headline')}
                          </Text>
                        </Stack>
                        <CustomerLogoGrid />
                      </Box>
                    </>
                  ) : isMobile ? (
                    <VStack align={'left'} width={'75%'}>
                      <HStack paddingLeft={'3px'} paddingRight={'3px'} pb={'5'}>
                        <ServiceRecordIcon fill={'blue.500'} boxSize="8" minWidth={'30px'} minHeight={'30px'} />

                        <Text>{t('components:trust_elements.signup_step.manufacturer_compliant')}</Text>
                      </HStack>
                      <HStack paddingRight={'10px'} pb={'5'}>
                        <ServiceHistoryRequestIcon color={'blue.500'} boxSize="8" minWidth={'30px'} minHeight={'30px'} />

                        <Text>{t('components:trust_elements.signup_step.time_expenditure')}</Text>
                      </HStack>
                      <HStack paddingRight={'10px'} pb={'5'}>
                        <Icon as={AiFillCar} boxSize="10" color={'blue.500'} />

                        <Text>{t('components:trust_elements.signup_step.one_portal')}</Text>
                      </HStack>
                      <HStack paddingRight={'10px'} pb={'5'}>
                        <Icon as={HiOutlineLightBulb} boxSize="10" color={'blue.500'} />

                        <Text>{t('components:trust_elements.signup_step.easy_handling')}</Text>
                      </HStack>
                      <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} color={'blue.500'} pt={15}>
                        {t('components:trust_elements.signup_step.partner_logos_headline')}
                      </Text>
                      <CustomerLogoGrid />
                    </VStack>
                  ) : null}
                </Box>
              </Stack>
            </Box>
          </VStack>
        ) : null}
        {expandForm && !isTablet && !isMobile ? (
          <HStack alignItems="start" gap={10}>
            <Box width={'50%'} borderRight={'1px'} borderRightColor={'gray.300'} height={'100%'}>
              <Stack spacing="5" pr={10}>
                {!abTestSignupFormNewDesign && (
                  <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
                    {t('workshop:registration.stepper.one.info.company.label')}
                  </Text>
                )}

                <Box>
                  <Stack spacing="6" direction={{ base: 'column', md: 'column' }} py={FORMFIELD_CONFIG.spacing}>
                    {simpleInput({
                      name: 'vat_number',
                      label: t('forms:vat_number.label'),
                      placeholder: t('forms:vat_number.placeholder'),
                      register,
                      description: t('forms:vat_number.description'),
                      showAsTooltip: true,
                      errors,
                      disabled: !country,
                      customClass: 'pii',
                      schema,
                      leftAddon: country !== 'CH' ? country : 'CHE',
                      customHook: (val) => {
                        setVatnumber(val);
                        const formatter = get(vatFormatting, country);
                        if (formatter) {
                          setValue('vat_number', formatter(val));
                        }
                        onChangeVat(val);
                      },
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    <HStack>
                      <Box width={'15%'} minWidth={90}>
                        <Tooltip label={`${t('forms:telephone.info')}`}>
                          <Text fontSize="xs" width="max" mt={1}>{`${t('forms:telephone.label')} *`}</Text>
                        </Tooltip>
                        {dropdown({
                          name: 'telephone_prefix',
                          control,
                          errors,
                          register,
                          schema,
                          options: telephonePrefixesOptions(telephonePrefixes, t),
                          boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                          bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                          placeHolderColor: abTestSignupFormNewDesign ? 'black' : undefined,
                          hideDropdownNoValueOption: true,
                          borderRadius: '8',
                        })}
                      </Box>
                      <Box width={'85%'}>
                        <Text fontSize="sm" color={'transparent'}>
                          Inv
                        </Text>
                        {simpleInput({
                          name: 'telephone',
                          placeholder: t('forms:telephone.placeholder'),
                          customHook: (val) => setValue('telephone', formatTelephone(val, getValues('telephone_prefix'))),
                          register,
                          errors,
                          schema,
                          customClass: 'pii',
                          boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                        })}
                      </Box>
                    </HStack>
                    {simpleInput({
                      name: 'address',
                      label: t('forms:address.label'),
                      placeholder: t('forms:address.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'city',
                      label: t('forms:city.label'),
                      placeholder: t('forms:city.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'zipcode',
                      label: t('forms:zipcode.label'),
                      placeholder: t('forms:zipcode.placeholder'),
                      register,
                      errors,
                      customHook: (val) => setValue('zipcode', formatZipcode(val, country)),
                      schema,
                      disabled: !country,
                      customClass: 'pii',
                      required: true,
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    <HStack minHeight={100}>
                      {simpleCheckBox({
                        disabled: !selectedLanguage,
                        name: 'consent_termsandconditions',
                        label: t('forms:consent_termsandconditions.label'),
                        fontSize: '11px',
                        description: (
                          <CheckBoxDescription
                            country={country}
                            selectedLanguage={selectedLanguage}
                            translationKey={'forms:consent_termsandconditions.info'}
                          />
                        ),
                        register,
                        errors,
                        schema,
                        borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                        borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                      })}

                      {simpleCheckBox({
                        disabled: !selectedLanguage,
                        name: 'consent_dataprivacy',
                        label: t('forms:consent_dataprivacy.label'),
                        fontSize: '11px',
                        description: (
                          <CheckBoxDescription
                            country={country}
                            selectedLanguage={selectedLanguage}
                            translationKey={'forms:consent_dataprivacy.info'}
                          />
                        ),
                        register,
                        errors,
                        schema,
                        borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                        borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                      })}
                    </HStack>
                  </Stack>
                </Box>
                {Object.keys(errors)[0] === 'email' && <Text color={'error'}>{t('forms:email.taken')}</Text>}
                <Flex direction="row-reverse" py="4" px={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
                  {expandForm && (
                    <Flex direction="row-reverse">
                      {!workshopVat && !isValidating && isValid ? (
                        <HStack>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                          <PortalButton
                            data-test-id="signup-next-button-no-vat"
                            //disabled={!isValid || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              NiceModal.show(AlertModal, {
                                children: <Trans i18nKey={'workshop:registration.without_vat.modal.body'} />,
                                onSubmit: () => onSubmit(getValues()),
                                content: {
                                  header: t('workshop:registration.without_vat.modal.title'),
                                  footer: {
                                    buttons: {
                                      cancelCaption: t('workshop:registration.without_vat.modal.buttons.cancel'),
                                      actionCaption: t('common:next'),
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            {t('workshop:registration.complete.label')} <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                        </HStack>
                      ) : (
                        <HStack>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                          <PortalButton
                            type="submit"
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                          >
                            {t('workshop:registration.complete.label')} <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                        </HStack>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Stack>
            </Box>
            <Box width={'50%'}>
              <Stack spacing="5">
                <Stack pt={0} mt={0}>
                  <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} pt={15} color={'blue.500'}>
                    {t('components:customer_reviews.header')}
                  </Text>
                  <Text fontSize={'sm'} align={'center'}>
                    {t('components:customer_reviews.subheader')}
                  </Text>

                  <VStack align={'center'}>
                    <HStack pt={10} align={'center'}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" padding={0} />
                      ))}
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.autopflege_center_kammer_gmbh.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.autopflege_center_kammer_gmbh.name')}</Text>
                  </VStack>
                  <VStack align={'center'}>
                    <HStack pt={10} align={'center'}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" />
                      ))}
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.kfz_ruhland_gmbh.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.kfz_ruhland_gmbh.name')}</Text>
                  </VStack>
                  <VStack align={'center'}>
                    <HStack pt={10}>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" />
                      ))}
                      <Icon key={'index'} as={IoMdStarHalf} boxSize="6" color="#f0bf4c" />
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.auto_moellig.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.auto_moellig.name')}</Text>
                  </VStack>
                  <Text fontSize={'sm'} align={'center'} pt={10}>
                    {t('components:customer_reviews.google')}
                  </Text>
                </Stack>
                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }} align={'center'}></Box>
              </Stack>
            </Box>
          </HStack>
        ) : (expandForm && isTablet) || (expandForm && isMobile) ? (
          <VStack alignItems="center" gap={10}>
            <Box
              //width={isMobile ? 'full' : '50%'}
              width={isMobile ? 'full' : isTablet ? '75%' : 'full'}
            >
              <Stack spacing="5" pr={10}>
                {!abTestSignupFormNewDesign && (
                  <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
                    {t('workshop:registration.stepper.one.info.company.label')}
                  </Text>
                )}

                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
                  <Stack spacing="6" direction={{ base: 'column', md: 'column' }} py={FORMFIELD_CONFIG.spacing}>
                    {simpleInput({
                      name: 'vat_number',
                      label: t('forms:vat_number.label'),
                      placeholder: t('forms:vat_number.placeholder'),
                      register,
                      description: t('forms:vat_number.description'),
                      showAsTooltip: true,
                      errors,
                      disabled: !country,
                      customClass: 'pii',
                      schema,
                      leftAddon: country !== 'CH' ? country : 'CHE',
                      customHook: (val) => {
                        setVatnumber(val);
                        const formatter = get(vatFormatting, country);
                        if (formatter) {
                          setValue('vat_number', formatter(val));
                        }
                        onChangeVat(val);
                      },
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    <HStack>
                      <Box width={'15%'} minWidth={90}>
                        <Tooltip label={`${t('forms:telephone.info')}`}>
                          <Text fontSize="xs" width="max" mt={1}>{`${t('forms:telephone.label')} *`}</Text>
                        </Tooltip>
                        {dropdown({
                          name: 'telephone_prefix',
                          control,
                          errors,
                          register,
                          schema,
                          options: telephonePrefixesOptions(telephonePrefixes, t),
                          boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                          bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                          placeHolderColor: abTestSignupFormNewDesign ? 'black' : undefined,
                          hideDropdownNoValueOption: true,
                          borderRadius: '8',
                        })}
                      </Box>
                      <Box width={'85%'}>
                        <Text fontSize="sm" color={'transparent'}>
                          Inv
                        </Text>
                        {simpleInput({
                          name: 'telephone',
                          placeholder: t('forms:telephone.placeholder'),
                          customHook: (val) => setValue('telephone', formatTelephone(val, getValues('telephone_prefix'))),
                          register,
                          errors,
                          schema,
                          customClass: 'pii',
                          boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                        })}
                      </Box>
                    </HStack>
                    {simpleInput({
                      name: 'address',
                      label: t('forms:address.label'),
                      placeholder: t('forms:address.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'city',
                      label: t('forms:city.label'),
                      placeholder: t('forms:city.placeholder'),
                      register,
                      errors,
                      schema,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {simpleInput({
                      name: 'zipcode',
                      label: t('forms:zipcode.label'),
                      placeholder: t('forms:zipcode.placeholder'),
                      register,
                      required: true,
                      errors,
                      customHook: (val) => setValue('zipcode', formatZipcode(val, country)),
                      schema,
                      disabled: !country,
                      customClass: 'pii',
                      boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    })}
                    {isMobile ? (
                      <VStack minHeight={100}>
                        {simpleCheckBox({
                          disabled: !selectedLanguage,
                          name: 'consent_termsandconditions',
                          label: t('forms:consent_termsandconditions.label'),
                          fontSize: '11px',
                          description: (
                            <CheckBoxDescription
                              country={country}
                              selectedLanguage={selectedLanguage}
                              translationKey={'forms:consent_termsandconditions.info'}
                            />
                          ),
                          register,
                          errors,
                          schema,
                          borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                          borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                        })}

                        {simpleCheckBox({
                          disabled: !selectedLanguage,
                          name: 'consent_dataprivacy',
                          label: t('forms:consent_dataprivacy.label'),
                          fontSize: '11px',
                          description: (
                            <CheckBoxDescription
                              country={country}
                              selectedLanguage={selectedLanguage}
                              translationKey={'forms:consent_dataprivacy.info'}
                            />
                          ),
                          register,
                          errors,
                          schema,
                          borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                          borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                        })}
                      </VStack>
                    ) : (
                      <HStack minHeight={100}>
                        {simpleCheckBox({
                          disabled: !selectedLanguage,
                          name: 'consent_termsandconditions',
                          label: t('forms:consent_termsandconditions.label'),
                          fontSize: '11px',
                          description: (
                            <CheckBoxDescription
                              country={country}
                              selectedLanguage={selectedLanguage}
                              translationKey={'forms:consent_termsandconditions.info'}
                            />
                          ),
                          register,
                          errors,
                          schema,
                          borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                          borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                        })}

                        {simpleCheckBox({
                          disabled: !selectedLanguage,
                          name: 'consent_dataprivacy',
                          label: t('forms:consent_dataprivacy.label'),
                          fontSize: '11px',
                          description: (
                            <CheckBoxDescription
                              country={country}
                              selectedLanguage={selectedLanguage}
                              translationKey={'forms:consent_dataprivacy.info'}
                            />
                          ),
                          register,
                          errors,
                          schema,
                          borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
                          borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
                        })}
                      </HStack>
                    )}
                  </Stack>
                </Box>
                {Object.keys(errors)[0] === 'email' && <Text color={'error'}>{t('forms:email.taken')}</Text>}
                <Flex direction="row-reverse" py="4" px={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
                  {expandForm && !isMobile ? (
                    <Flex direction="row-reverse">
                      {!workshopVat && !isValidating && isValid ? (
                        <HStack>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                          <PortalButton
                            data-test-id="signup-next-button-no-vat"
                            //disabled={!isValid || isLoading}

                            isLoading={isLoading}
                            onClick={() => {
                              NiceModal.show(AlertModal, {
                                children: <Trans i18nKey={'workshop:registration.without_vat.modal.body'} />,
                                onSubmit: () => onSubmit(getValues()),
                                content: {
                                  header: t('workshop:registration.without_vat.modal.title'),
                                  footer: {
                                    buttons: {
                                      cancelCaption: t('workshop:registration.without_vat.modal.buttons.cancel'),
                                      actionCaption: t('workshop:registration.complete.label'),
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            {t('workshop:registration.complete.label')} <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                        </HStack>
                      ) : (
                        <HStack>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                          <PortalButton
                            type="submit"
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                          >
                            {t('workshop:registration.complete.label')}
                            <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                        </HStack>
                      )}
                    </Flex>
                  ) : expandForm && isMobile ? (
                    <Flex direction="row-reverse">
                      {!workshopVat && !isValidating && isValid ? (
                        <VStack>
                          <PortalButton
                            data-test-id="signup-next-button-no-vat"
                            //disabled={!isValid || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              NiceModal.show(AlertModal, {
                                children: <Trans i18nKey={'workshop:registration.without_vat.modal.body'} />,
                                onSubmit: () => onSubmit(getValues()),
                                content: {
                                  header: t('workshop:registration.without_vat.modal.title'),
                                  footer: {
                                    buttons: {
                                      cancelCaption: t('workshop:registration.without_vat.modal.buttons.cancel'),
                                      actionCaption: t('workshop:registration.complete.label'),
                                    },
                                  },
                                },
                              });
                            }}
                          >
                            {t('workshop:registration.complete.label')} <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                        </VStack>
                      ) : (
                        <VStack>
                          <PortalButton
                            type="submit"
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                          >
                            {t('workshop:registration.complete.label')} <Icon as={FiArrowRight} boxSize="4" />
                          </PortalButton>
                          <Button
                            data-test-id="signup-next-button"
                            //disabled={(submitCount! > 0 && !isValid) || isLoading}
                            isLoading={isLoading}
                            onClick={() => {
                              setExpandForm(false);
                            }}
                          >
                            <Icon as={FiArrowLeft} boxSize="4" />
                            {t('common:back')}
                          </Button>
                        </VStack>
                      )}
                    </Flex>
                  ) : null}
                </Flex>
              </Stack>
            </Box>
            <Box width={isMobile ? 'full' : '50%'}>
              <Stack spacing="5">
                <Stack pt={0} mt={0}>
                  <Text fontSize="xl" fontWeight={FONT_WEIGHTS.bold} align={'center'} pt={15} color={'blue.500'}>
                    {t('components:customer_reviews.header')}
                  </Text>
                  <Text fontSize={'sm'} align={'center'}>
                    {t('components:customer_reviews.subheader')}
                  </Text>

                  <VStack align={'center'}>
                    <HStack pt={10} align={'center'}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" padding={0} />
                      ))}
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.autopflege_center_kammer_gmbh.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.autopflege_center_kammer_gmbh.name')}</Text>
                  </VStack>
                  <VStack align={'center'}>
                    <HStack pt={10} align={'center'}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" />
                      ))}
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.kfz_ruhland_gmbh.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.kfz_ruhland_gmbh.name')}</Text>
                  </VStack>
                  <VStack align={'center'}>
                    <HStack pt={10}>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <Icon key={index} as={IoMdStar} boxSize="6" color="#f0bf4c" />
                      ))}
                      <Icon key={'index'} as={IoMdStarHalf} boxSize="6" color="#f0bf4c" />
                    </HStack>
                    <Text fontSize={'lg'} textAlign="center" whiteSpace="normal" wordBreak="break-word">
                      {t('components:customer_reviews.auto_moellig.text')}
                    </Text>
                    <Text fontSize={'sm'}>{t('components:customer_reviews.auto_moellig.name')}</Text>
                  </VStack>
                  <Text fontSize={'sm'} align={'center'} pt={10}>
                    {t('components:customer_reviews.google')}
                  </Text>
                </Stack>
                <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }} align={'center'}></Box>
              </Stack>
            </Box>
          </VStack>
        ) : null}
      </Box>
    </Box>
  );
};

export default SignupStepSplitted;
