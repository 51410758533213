import { setUser } from '@sentry/browser';
import React from 'react';
import { useQueryClient } from 'react-query';

import { ActivatedAddon, Config, StatusCodes, User } from '../api/types';
import { LoadingSpinner } from '../components/LoadingSpinner';
import useConfigQuery from '../hooks/queries/useConfigQuery';
import useStatusQuery from '../hooks/queries/useStatusQuery';
import useTokenQuery from '../hooks/queries/useTokenQuery';
import useWorkshopQuery, { Subscription, WorkshopData } from '../hooks/queries/workshop/useWorkshopQuery';
import useWorkshopUserQuery from '../hooks/queries/workshop/useWorkshopUserQuery';
import ErrorPage from '../layout/errors/ErrorPage';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';
import { isWithinTwoWeeks } from 'helpers/general';
import { ADDON_KEYS } from 'api/types';
import { stopPosthogRecording } from 'helpers/posthogHelper';

export interface UserContextInterface {
  login: () => void;
  logout: () => Promise<void>;
  user: User;
  workshop: WorkshopData;
  subscription: Subscription;
  config: Config;
  statusCodes: StatusCodes;
  error?: Error | null;
}

const UserContext = React.createContext<UserContextInterface>({
  user: {},
  workshop: {},
  subscription: {},
  statusCodes: {},
  config: {},
  error: null,
} as UserContextInterface);

export const WORKSHOP_PREREGISTERED = 0;
export const WORKSHOP_OLD_EXISTING_CUSTOMER = 50;
export const WORKSHOP_REGISTERED = 100;
export const WORKSHOP_ACCOUNT_SETUP = 200;
export const WORKSHOP_ACTIVE = 300;
export const WORKSHOP_DEACTIVATED = 900;
export const STATUS_CREATED = 0;
export const STATUS_COMPLETED = 700;
export const STATUS_NEEDS_CLARIFICATION = 500;
export const STATUS_REJECTED = 900;
export const WORKSHOP_LOCKED = 800;

export const enum WORKSHOP_STATUS_NUMBERS {
  WORKSHOP_PREREGISTERED = 0,
  WORKSHOP_OLD_EXISTING_CUSTOMER = 50,
  WORKSHOP_REGISTERED = 100,
  WORKSHOP_ACCOUNT_SETUP = 200,
  WORKSHOP_ACTIVE = 300,
  WORKSHOP_DEACTIVATED = 900,
  WORKSHOP_LOCKED = 800,
}
const UserContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const queryClient = useQueryClient();
  const userQuery = useWorkshopUserQuery();
  const workshopQuery = useWorkshopQuery();
  const configQuery = useConfigQuery();
  const statusQuery = useStatusQuery();

  const tokenQuery = useTokenQuery();

  const location = useLocation();

  const login = () => {
    queryClient.invalidateQueries(useWorkshopQuery.getKey());
    queryClient.invalidateQueries(useConfigQuery.getKey());
    queryClient.invalidateQueries(useTokenQuery.getKey());
  };

  const logout = async () => {
    queryClient.setQueryData(useWorkshopQuery.getKey(), null);
    queryClient.setQueryData(useTokenQuery.getKey(), null);
    queryClient.setQueryData(useConfigQuery.getKey(), null);
    setUser(null);
  };

  if (tokenQuery.isLoading || userQuery.isLoading || workshopQuery.isLoading || configQuery.isLoading || statusQuery.isLoading) {
    return <LoadingSpinner />;
  } else if (
    (userQuery.isError && userQuery.error?.response?.status !== 503) ||
    (workshopQuery.isError && workshopQuery.error?.response?.status !== 503) ||
    (configQuery.isError && configQuery.error?.response?.status !== 503) ||
    (statusQuery.isError && statusQuery.error?.response?.status !== 503)
  ) {
    return <ErrorPage error={'Timeout while authenticating user'} />;
  }

  let value: UserContextInterface = {
    login,
    logout,
    user: userQuery.data?.data as User,
    workshop: workshopQuery.data?.data as WorkshopData,
    error: workshopQuery.error as unknown as Error,
    subscription: workshopQuery.data?.data?.subscription as Subscription,
    statusCodes: statusQuery.data?.data as StatusCodes,
    config: configQuery.data?.data as Config,
  };

  let workshop = value.workshop;
  let user = value.user;
  let activeAddons: { [key: string]: ActivatedAddon } = workshop?.subscription?.active_addons;

  let isPastTwoWeeks = false;
  if (workshop.registered_at) {
    isPastTwoWeeks = isWithinTwoWeeks(workshop.registered_at);
  }
  let userRole: string | null = '';
  if (localStorage.getItem('userRole')) {
    userRole = localStorage.getItem('userRole');
  }

  if (isPastTwoWeeks || (location.pathname.includes('/support') || location.pathname.includes('/help/service-process'))) {
    posthog.identify(user.id.toString(), {
      role: userRole,
      workshop: {
        workshop_id: workshop.id,
        language: workshop.language?.toUpperCase(),
        country: workshop.country?.toUpperCase(),
        first_positive_verification_submitted_at: workshop.first_positive_verification_submitted_at,
        first_positive_verification_approved_at: workshop.first_positive_verification_approved_at,
        verified: workshop.is_verified,
        registered_at: workshop.registered_at,
        activated_at: workshop.activated_at,
        subscription: {
          plan_slug: workshop?.subscription?.plan?.slug,
          billing_cycle: workshop?.subscription?.billing_cycle,
          inspection_plans_addon: workshop?.subscription ? activeAddons[ADDON_KEYS.INSPECTION_PLANS_ADDON] : null,
          oe_docs_addon: workshop?.subscription ? activeAddons[ADDON_KEYS.OE_DOCS] : null,
          oe_plus_addon: workshop?.subscription ? activeAddons[ADDON_KEYS.PLUS_DSB] : null,
          qr_connect_addon: workshop?.subscription ? activeAddons[ADDON_KEYS.QR_CONNECT] : null,
        },
      },
    });
    workshop?.registered_at? localStorage.setItem('workshop_registered_at', workshop?.registered_at) : '';
  } else {
    if (!location.pathname.includes('setup') && !location.pathname.includes('signup')) {
      localStorage.removeItem('workshop_registered_at');
      stopPosthogRecording();
    }
  }
  posthog.alias(user.email, user.id.toString());

  return <UserContext.Provider value={value} {...props} />;
};

const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('Trying to access userContext outside of UserContextProvider');
  }
  return context;
};

export { UserContextProvider, useUserContext };
