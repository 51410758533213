import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isWithinTwoWeeks } from 'helpers/general';
import { startPosthogRecording } from 'helpers/posthogHelper';

export default function PostHogPageView() {
  let location = useLocation();
  const registered_at = localStorage.getItem('workshop_registered_at');

  useEffect(() => {
    if (posthog) {
      if (
        location?.pathname.includes('/signup') ||
        location?.pathname.includes('/setup') ||
        location?.pathname.includes('/support') ||
        location?.pathname.includes('/help/service-process') ||
        (registered_at && isWithinTwoWeeks(registered_at))
      ) {
        startPosthogRecording();
      }
    }
  }, [location, registered_at]);

  return null;
}
