import { Box, Button, Divider, Flex, Heading, HStack, Icon, IconButton, Square, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { LoadingSpinner } from 'components/LoadingSpinner';
import AlertModal from 'components/modals/AlertModal';
import config from 'config';
import { queryClient } from 'configs/queryClient';
import { useAxios } from 'context/AxiosContextProvider';
import { format } from 'date-fns';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import useCreateServiceRecordFromInspectionPlan from 'hooks/private/mutations/useCreateServiceRecordFromInspectionPlan';
import useUpdateInspectionPlanMutation from 'hooks/private/mutations/useUpdateInspectionPlanMutation';
import useUpdateInspectionPlanStatusMutation, { IPStatusType } from 'hooks/private/mutations/useUpdateInspectionPlanStatusMutation';
import useShowInspectionPlanQuery from 'hooks/queries/inspection_plan/useShowInspectionPlanQuery';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { Card } from 'layout/Card';
import PageMetatags from 'layout/PageMetatags';
import { debounce } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BiPrinter, BsWrench, IoBanOutline, IoIosWarning, TbThumbDown, TbThumbUp } from 'react-icons/all';
import { MdFormatListBulleted } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserContext } from '../../context/UserContextProvider';
import InspectionTask from './InspectionTask';

const InspectionPlanDetailsPage = () => {
  const axios = useAxios();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const posthog = usePostHog();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const isServiceCompleted = urlParams.get('isServiceCompleted') === 'true';
  const { id: inspectionPlanID } = useParams();

  const debouncedMutationRef = useRef<ReturnType<typeof debounce> | null>(null);
  const isInitialMount = useRef(true);

  const [submission, setSubmission] = useState(false);
  const [disableSubmission, setDisableSubmission] = useState(false);
  const [save, setSave] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<Array<{ [key: string]: string }>>([]);
  const [allTasksChecked, setAllTasksChecked] = useState(false);

  const createServiceRecordFromInspectionPlan = useCreateServiceRecordFromInspectionPlan(vin, inspectionPlanID as string);

  const vehicleQuery = useVehicleQuery(vin);
  const vehicleData = vehicleQuery.data?.data;
  const isDisabled = vehicleData?.active_service?.completed_at !== null;

  const accountBlockedDeletedInProgress =
    vehicleData?.account?.status === 100 || vehicleData?.account?.status === 700 || vehicleData?.account?.status === 900;

  const isServiceRecordCreatedFromInspectionPlan = vehicleData?.active_service?.service_activities?.some((service: any) => {
    return String(service?.service_record?.from_inspection_plan_id) === String(inspectionPlanID);
  });

  // isServiceRecoredNotCompleted

  const serviceRecordExists = vehicleData?.active_service?.service_activities?.some((service: any) => {
    return service.type === 'service_record';
  });

  const inspectionPlanQuery = useShowInspectionPlanQuery(vin, inspectionPlanID);

  const inspectionPlanData = inspectionPlanQuery.data?.data;
  const inspectionTasks = inspectionPlanData?.maintenance_tasks?.task_groups;
  const lastUpdatedAt = inspectionPlanData?.updated_at;
  const isInspectionPlanStatusCompleted = inspectionPlanData?.status === 700;
  const updateInspectionPlanMutation = useUpdateInspectionPlanMutation(vin, inspectionPlanID as string);
  const updateInspectionPlanStatusMutation = useUpdateInspectionPlanStatusMutation(vin, inspectionPlanID as string);

  const supportPhoneNumber = userContext?.config?.support_telephone;
  const supportEmail = userContext?.config?.support_email;

  // logic to check if all tasks are checked
  const totalTasksLength = inspectionTasks?.reduce((sum: number, inspection: any) => {
    return sum + (inspection?.tasks ? inspection?.tasks?.length : 0);
  }, 0);

  useEffect(() => {
    if (selectedTasks.length === totalTasksLength) {
      setAllTasksChecked(true);
    } else {
      setAllTasksChecked(false);
    }
  }, [selectedTasks]);

  const accountRejected = vehicleData?.account?.status === 800;

  const getUpdatedTimestamp = (lastUpdatedAt: string) => {
    const parsedDate = new Date(lastUpdatedAt);
    const formattedDate = format(parsedDate, 'M.d.y');
    const formattedTime = format(parsedDate, 'H:mm');

    return t('forms:ip.last_saved_on', {
      date: formattedDate,
      time: formattedTime,
    });
  };

  // set selected tasks from query data
  useEffect(() => {
    if (inspectionPlanQuery.data?.data?.data) {
      const dataObject = inspectionPlanQuery.data.data.data as {
        [key: string]: string;
      }[];
      const preparedData = Object.entries(dataObject).map(([key, value]) => ({
        [key]: value,
      }));
      // @ts-ignore
      setSelectedTasks(preparedData);
    }
  }, [inspectionPlanQuery.isFetchedAfterMount]);

  const [flag, setFlag] = useState(false);

  // set selected tasks from query data
  useEffect(() => {
    if (inspectionPlanQuery.data?.data?.data && flag) {
      const dataObject = inspectionPlanQuery.data.data.data as {
        [key: string]: string;
      }[];
      const preparedData = Object.entries(dataObject).map(([key, value]) => ({
        [key]: value,
      }));
      // @ts-ignore
      setSelectedTasks(preparedData);
      setFlag(false);
    }
  }, [inspectionPlanQuery.data]);

  // final submission with status change
  useEffect(() => {
    if (submission) {
      updateInspectionPlanMutation.mutate(
        selectedTasks.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }),
        {
          onSuccess: () => {
            setFlag(true);
            updateInspectionPlanStatusMutation.mutate(700, {
              onSuccess: () => {
                posthog.capture('completed_inspection_plan');
                navigate(`/app/w/completed-inspection-plan/${inspectionPlanID}?vin=${vin}`);
                queryClient.invalidateQueries(useShowInspectionPlanQuery.getKey(vin, inspectionPlanID)).then(() => {
                  setSubmission(false);
                });

                // window.location.reload();
              },
            });
          },
        },
      );
    }
  }, [submission]);

  const [changeStatusToCompleted, setChangeStatusToCompleted] = useState(false);

  // status change to completed only
  useEffect(() => {
    if (changeStatusToCompleted) {
      updateInspectionPlanMutation.mutate(
        selectedTasks.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }),
        {
          onSuccess: () => {
            setFlag(true);
            updateInspectionPlanStatusMutation.mutate(700, {
              onSuccess: () => {
                queryClient.invalidateQueries(useShowInspectionPlanQuery.getKey(vin, inspectionPlanID)).then(() => {
                  setChangeStatusToCompleted(false);
                });

                // window.location.reload();
              },
            });
          },
        },
      );
    }
  }, [changeStatusToCompleted]);

  // regular submission for saving
  useEffect(() => {
    if (save) {
      if (debouncedMutationRef.current) {
        debouncedMutationRef.current.cancel();
      }
      updateInspectionPlanMutation.mutate(
        selectedTasks.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }),
        {
          onSuccess: () => {
            setDisableSubmission(false);
            setSave(false);
          },
        },
      );
    }
  }, [save]);

  const debouncedMutation = useCallback(
    debounce((selectedTasks) => {
      updateInspectionPlanMutation.mutate(
        selectedTasks.reduce(
          (acc: Record<string, string>, curr: { [key: string]: string }) => ({
            ...acc,
            ...curr,
          }),
          {},
        ),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(useShowInspectionPlanQuery.getKey(vin, inspectionPlanID));
            setDisableSubmission(false);
          },
        },
      );
    }, 1500),
    [vin],
  );
  debouncedMutationRef.current = debouncedMutation;

  // auto save logic
  useEffect(() => {
    if (
      !isServiceCompleted &&
      !isInitialMount.current &&
      !isInitialMount.current &&
      selectedTasks.length > 0 &&
      !save &&
      !isInspectionPlanStatusCompleted
    ) {
      setDisableSubmission(true);
      debouncedMutation(selectedTasks);
    }
  }, [selectedTasks]);

  // prevention auto save logic to run on initial mount
  setTimeout(() => {
    isInitialMount.current = false;
  }, 2000);

  const changeInspectionPlanStatus = (status: IPStatusType) => {
    updateInspectionPlanStatusMutation.mutate(status, {
      onSuccess: () => {
        queryClient.invalidateQueries(useShowInspectionPlanQuery.getKey(vin, inspectionPlanID));
      },
    });
  };

  const createServiceRecord = () => {
    // @ts-ignore
    createServiceRecordFromInspectionPlan.mutate(null, {
      onSuccess: (data: any) => {
        posthog.capture('converted_inspection_plan');
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
        navigate(`/app/w/service-record/${data?.data?.data?.id}/update?vin=${vin}`);
      },
    });
  };

  const ButtonSection = (
    <HStack justifyContent={{ base: 'flex-start', lg: 'flex-end' }} width="100%" style={{ marginTop: 40, marginBottom: 20 }}>
      <Tooltip
        placement={'left'}
        label={t('forms:ip.inspection_plan_edit_tooltip')}
        shouldWrapChildren
        isDisabled={!isServiceRecordCreatedFromInspectionPlan}
      >
        <Button
          variant="outline"
          colorScheme="blue.500"
          disabled={isServiceCompleted || isDisabled || isServiceRecordCreatedFromInspectionPlan || submission || selectedTasks.length === 0}
          isLoading={(!submission && updateInspectionPlanMutation.isLoading) || (!submission && updateInspectionPlanStatusMutation.isLoading)}
          loadingText={isInspectionPlanStatusCompleted ? 'Enabeling edit' : t('forms:ip.currently_saving')}
          onClick={() => {
            if (isInspectionPlanStatusCompleted) {
              changeInspectionPlanStatus(200);
            } else {
              setSave(true);
            }
          }}
          mr={5}
        >
          {isInspectionPlanStatusCompleted ? t('common:edit') : t('common:save')}
        </Button>
      </Tooltip>
      <Tooltip
        placement={'left'}
        label={
          isInspectionPlanStatusCompleted && vehicleData?.features?.create_service_record === 'not_available_workshop_not_verified'
            ? t('forms:ip.workshop_not_verified')
            : selectedTasks?.length === 0
              ? t('forms:ip.service_record_complete_tooltip')
              : t('forms:ip.service_record_processing_tooltip')
        }
        shouldWrapChildren
        isDisabled={
          isInspectionPlanStatusCompleted && vehicleData?.features?.create_service_record === 'not_available_workshop_not_verified'
            ? false
            : selectedTasks?.length === 0
              ? false
              : !serviceRecordExists || (serviceRecordExists && !isInspectionPlanStatusCompleted)
        }
      >
        <Button
          variant="solid"
          colorScheme="blue"
          disabled={
            isServiceCompleted ||
            isDisabled ||
            disableSubmission ||
            selectedTasks.length === 0 ||
            (selectedTasks.length > 0 && updateInspectionPlanMutation.isLoading) ||
            (serviceRecordExists && isInspectionPlanStatusCompleted) ||
            (isInspectionPlanStatusCompleted && vehicleData?.features?.create_service_record === 'not_available_workshop_not_verified') ||
            submission
          }
          isLoading={submission}
          onClick={() => {
            if (isInspectionPlanStatusCompleted && vehicleData?.features?.create_service_record === 'not_available_account_not_registered') {
              NiceModal.show(AlertModal, {
                accountRegistrationNotification: true,
                notifyWhenReady: vehicleData?.account?.notify_when_ready,
                accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                accountId: vehicleData?.account?.id,
                onSubmit: () => navigate('/app/w/account-registration-status'),
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} boxSize="8" />
                    </Square>
                  ),
                  header: t('components:manufacturer_not_registered.header'),
                  footer: {
                    buttons: {
                      hide: true,
                      actionCaption: t('components:manufacturer_not_registered.cta'),
                    },
                  },
                },
                children: t('components:manufacturer_not_registered.text'),
              });
              //if the workshop is verified, and the vehicle has no DSB, or it has a DSB which either doesn't require an account or the account is already active, show the user the SR form
            } else if (isInspectionPlanStatusCompleted && vehicleData?.features?.create_service_record === 'not_available_account_rejected') {
              NiceModal.show(AlertModal, {
                accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                accountId: vehicleData?.account?.id,
                content: {
                  icon: <Icon as={IoIosWarning} color={'red'} boxSize="8" />,
                  header: t('components:manufacturer_account_rejected.title'),
                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                children: (
                  <Box mt={5} mb={5}>
                    <Trans
                      i18nKey="components:manufacturer_account_rejected.message"
                      values={{
                        phoneNumber: supportPhoneNumber,
                        email: supportEmail,
                      }}
                    />
                  </Box>
                ),
              });
            } else if (isInspectionPlanStatusCompleted && !serviceRecordExists) {
              createServiceRecord();
              // navigate(
              //   `/app/w/completed-inspection-plan/${inspectionPlanID}?vin=${vin}`,
              // );
            } else if (!isInspectionPlanStatusCompleted && allTasksChecked) {
              setSubmission(true);
              // setChangeStatusToCompleted(true);
              // navigate()....
            } else if (!isInspectionPlanStatusCompleted && !allTasksChecked) {
              NiceModal.show(AlertModal, {
                onSubmit: () => {
                  setSubmission(true);
                  // navigate(
                  //   `/app/w/completed-inspection-plan/${inspectionPlanID}?vin=${vin}`,
                  // );
                },
                content: {
                  header: t('forms:ip.complete_inspection'),
                  footer: {
                    buttons: {
                      cancelCaption: t('common:cancel'),
                      actionCaption: t('common:continue'),
                    },
                  },
                },
                children: t('forms:ip.complete_not_all_checked'),
              });
            }
          }}
        >
          {isInspectionPlanStatusCompleted ? t('forms:ip.create_service_entry') : t('forms:ip.complete_inspection')}
        </Button>
      </Tooltip>
    </HStack>
  );

  if (inspectionPlanQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags title={t('forms:ip.label')} />
      <Card>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4" p={4} justify="space-between" position="relative">
          <HStack>
            <Icon as={MdFormatListBulleted} boxSize={'50'} />
            <VStack align="start">
              <Heading size="xs" mb={-2}>
                {t('forms:ip.fillout.header')}
              </Heading>
              <Text color="muted" fontSize="sm">
                {t('forms:ip.fillout.description')}
              </Text>
            </VStack>
          </HStack>
          <Flex justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Tooltip label={t('forms:ip.print')} placement={'left'} shouldWrapChildren>
              <IconButton
                aria-label="print-button"
                color="blue.500"
                onClick={() =>
                  downloadFileFromUrl(
                    axios,
                    `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${inspectionPlanID}/${selectedTasks.length !== 0 ? 'prefilled-download' : 'blank-download'}`,
                    'Inspection Plan.pdf',
                  )
                }
                icon={<BiPrinter size={'xl'} />}
                bg="transparent"
                _hover={{ bg: 'blue.100' }}
                _focus={{ bg: 'blue.100' }}
              />
            </Tooltip>
          </Flex>
          <Text position={'absolute'} right={2} top={{ base: -4, md: 1 }} width={'max-content'} color="muted" fontSize="xs" mt={-7} mb={2}>
            {getUpdatedTimestamp(lastUpdatedAt)}
          </Text>
        </Stack>
        <Divider />
        <VStack px={5} width="100%">
          <Flex width="100%" flexDirection={{ base: 'column', lg: 'row' }} flexWrap={'nowrap'}>
            {/* <Stack flexDirection={{ base: 'column', md: 'row' }} style={{ alignItems: 'center' }} mb={5} width={'100%'} wrap={'wrap'}> */}
            <VStack align="flex-start" width="max-content" justify="space-around">
              <Text as={'b'} fontSize={18}>
                {t('forms:ip.legend')}
              </Text>
              <HStack mb={15} minWidth={'min-content'}>
                <Flex alignItems="center">
                  <Icon as={TbThumbUp} boxSize={6} color={'green.500'} />
                  <Text pl={1} fontSize="14" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {`...${t('forms:ip.ok')}`}
                  </Text>
                </Flex>
                <Flex alignItems="center" ml={10}>
                  <Icon as={TbThumbDown} boxSize={6} color={'red.500'} />
                  <Text pl={1} fontSize="14" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {`...${t('forms:ip.not_ok')}`}
                  </Text>
                </Flex>
                <Flex alignItems="center" ml={10}>
                  <Icon as={BsWrench} boxSize={5} color={'blue.500'} flexWrap={'nowrap'} />
                  <Text pl={1} fontSize="14" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {`...${t('forms:ip.repaired')}`}
                  </Text>
                </Flex>
                <Flex alignItems="center" ml={10} mr={5}>
                  <Icon as={IoBanOutline} boxSize={5} color={'black'} />
                  <Text pl={1} fontSize="14" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {`...${t('forms:ip.not_due')}`}
                  </Text>
                </Flex>
              </HStack>
            </VStack>
            {ButtonSection}
            {/* </Stack> */}
          </Flex>
          {inspectionTasks?.map((inspection: any, index: number) => (
            <InspectionTask
              isInspectionPlanStatusCompleted={isInspectionPlanStatusCompleted}
              selectedTasks={selectedTasks}
              isServiceCompleted={isServiceCompleted}
              setSelectedTasks={setSelectedTasks}
              name={inspection?.name}
              tasks={inspection?.tasks}
              icon={inspection?.icon}
              key={index}
            />
          ))}
          {ButtonSection}
        </VStack>
      </Card>
    </>
  );
};

export default InspectionPlanDetailsPage;
