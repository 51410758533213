import { Box, Button, Center, Divider, Grid, Heading, HStack, Icon, Image, Skeleton, Square, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import AlertModal from 'components/modals/AlertModal';
import config from 'config';
import { queryClient } from 'configs/queryClient';
import { useAxios } from 'context/AxiosContextProvider';
import { useUserContext } from 'context/UserContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import useCreateServiceRecordFromInspectionPlan from 'hooks/private/mutations/useCreateServiceRecordFromInspectionPlan';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import { Card } from 'layout/Card';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IoIosCar, IoIosWarning } from 'react-icons/io';
import { MdDownload, MdOutlineArrowRightAlt } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

const InspectionPlanCompletedPage = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const posthog = usePostHog();
  const { id: inspectionPlanID } = useParams();

  const createServiceRecordFromInspectionPlan = useCreateServiceRecordFromInspectionPlan(vin, inspectionPlanID as string);

  const vehicleQuery = useVehicleQuery(vin);
  const vehicleData = vehicleQuery.data?.data;

  const userContext = useUserContext();
  const supportPhoneNumber = userContext?.config?.support_telephone;
  const supportEmail = userContext?.config?.support_email;

  const accountBlockedDeletedInProgress =
    vehicleData?.account?.status === 100 || vehicleData?.account?.status === 700 || vehicleData?.account?.status === 900;
  const accountRejected = vehicleData?.account?.status === 800;

  const createServiceRecord = () => {
    // @ts-ignore
    createServiceRecordFromInspectionPlan.mutate(null, {
      onSuccess: (data: any) => {
        posthog.capture('converted_inspection_plan');
        queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
        navigate(`/app/w/service-record/${data?.data?.data?.id}/update?vin=${vin}`);
      },
    });
  };

  const serviceRecordExists = vehicleData?.active_service?.service_activities?.some((service: any) => {
    return service.type === 'service_record';
  });

  const workshopNotVerified = vehicleData?.features?.create_service_record === 'not_available_workshop_not_verified';

  const isLoading = false;

  return (
    <Card>
      <Stack spacing="4" p={8}>
        {isLoading ? (
          <Skeleton height="40px" />
        ) : (
          <HStack my={-3}>
            <Stack justifyContent={'center'} alignItems={'center'} mr={5}>
              <Icon as={IoIosCar} boxSize={14} />
            </Stack>

            <VStack align="start">
              <Heading size="xs">{t('forms:ip.completed.title')}</Heading>
              <Text color="muted" fontSize="sm">
                {t('forms:ip.completed.sub_title')}
              </Text>
            </VStack>
          </HStack>
        )}
      </Stack>
      <Divider />
      <Stack direction={['column']} paddingBlock={4}>
        {isLoading ? (
          <Box paddingInline={8}>
            <Skeleton height={300} />
          </Box>
        ) : (
          <Grid w="full" h="full" placeItems="center" p={4} gap={4}>
            <VStack>
              <Stack p={4}>
                <Center>
                  <Image src="/images/alex-thumbs-up.svg" alt="Error Gif" w="50%" h="auto" />
                </Center>
                <Text>{t('forms:ip.completed.text')}</Text>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }}>
                <Button
                  minW={60}
                  leftIcon={<MdDownload size={25} style={{ marginRight: 15 }} />}
                  colorScheme="blue"
                  size="lg"
                  onClick={() =>
                    downloadFileFromUrl(
                      axios,
                      `${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/${inspectionPlanID}/prefilled-download`,
                      'Inspection Plan.pdf',
                    )
                  }
                  justifyContent="center"
                  alignItems="center"
                  variant="outline"
                >
                  {t('common:download')}
                </Button>

                <Tooltip
                  placement={'left'}
                  label={workshopNotVerified ? t('forms:ip.workshop_not_verified') : t('forms:ip.service_record_processing_tooltip')}
                  shouldWrapChildren
                  isDisabled={!(serviceRecordExists || workshopNotVerified)}
                >
                  <Button
                    isLoading={createServiceRecordFromInspectionPlan.isLoading || vehicleQuery.isLoading}
                    style={{ marginLeft: 20 }}
                    minW={60}
                    disabled={serviceRecordExists || workshopNotVerified}
                    rightIcon={<MdOutlineArrowRightAlt size={25} style={{ marginLeft: 5 }} />}
                    colorScheme="blue"
                    size="lg"
                    onClick={() => {
                      if (vehicleData?.features?.create_service_record === 'not_available_account_not_registered') {
                        NiceModal.show(AlertModal, {
                          accountRegistrationNotification: true,
                          notifyWhenReady: vehicleData?.account?.notify_when_ready,
                          accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                          accountId: vehicleData?.account?.id,
                          onSubmit: () => navigate('/app/w/account-registration-status'),
                          content: {
                            icon: (
                              <Square size="10" borderRadius="md" display={'inline'}>
                                <Icon color={'orange'} boxSize="8" />
                              </Square>
                            ),
                            header: t('components:manufacturer_not_registered.header'),
                            footer: {
                              buttons: {
                                hide: true,
                                actionCaption: t('components:manufacturer_not_registered.cta'),
                              },
                            },
                          },
                          children: t('components:manufacturer_not_registered.text'),
                        });
                      } else if (vehicleData?.features?.create_service_record === 'not_available_account_rejected') {
                        NiceModal.show(AlertModal, {
                          accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                          accountId: vehicleData?.account?.id,
                          content: {
                            icon: <Icon as={IoIosWarning} color={'red'} boxSize="8" />,
                            header: t('components:manufacturer_account_rejected.title'),
                            footer: {
                              buttons: {
                                hide: true,
                              },
                            },
                          },
                          children: (
                            <Box mt={5} mb={5}>
                              <Trans
                                i18nKey="components:manufacturer_account_rejected.message"
                                values={{
                                  phoneNumber: supportPhoneNumber,
                                  email: supportEmail,
                                }}
                              />
                            </Box>
                          ),
                        });
                      } else {
                        createServiceRecord();
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {t('forms:ip.create_service_entry')}
                  </Button>
                </Tooltip>
              </Stack>
            </VStack>
          </Grid>
        )}
      </Stack>
    </Card>
  );
};

export default InspectionPlanCompletedPage;
